import React from 'react';
import { ReactComponent as AccuratorLogo } from '../../assets/images/aceve/accurator_dark.svg';
import { ReactComponent as AccuratorSymbol } from '../../assets/images/aceve/accurator_symbol.svg';


export default function Logo({ size = '32px', type }) {
  if(type === "logoWithTitle") {
    return <div style={{ width: size }}><AccuratorLogo width={size} /></div>;
  }
  return <AccuratorSymbol />;
}
