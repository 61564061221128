import './OptionsMenu.css';
import DotsIcon from '../../svgs/dotsIcon';
import { CSSProperties, ReactChild, ReactNode } from 'react';
import CheckIcon from '@material-ui/icons/Check';
/**
 * 
 * @param options: { label: String, action: () => void, icon: String | component }[]
 * @param selectedItem?: String
 */

type OptionsItem = {
  label: string,
  action: (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  icon?: ReactChild,
  key?: string | number,
}

type Props = {
  selectedItem?: string,
  options: OptionsItem[],
  children?: ReactNode,
  style?: CSSProperties,
  isMenuLeft?: boolean
}

export default function OptionsMenu({ selectedItem, options, children, style, isMenuLeft }: Props) {
  if (!options || options.length == 0) return null;
  return (
    <div className='sp-action-icon' style={style} tabIndex={0}>
      <button className={`${children ? 'trigger' : 'defaultTrigger'}`}>
        {children || <DotsIcon size='16px' color='var(--gray-700)' />}
      </button>

      {options && options.length > 0 && (
        <div className={`sp-action-menu ${isMenuLeft ? 'menuLeft' : ''}`}>
          {options.map(option => (
            <a key={option.key || option.label} href="#" onClick={e => {
              e.preventDefault();
              option.action();
              try {
                if (document?.activeElement) {
                  (document?.activeElement as HTMLElement)?.blur();
                }
              } catch (error) {
                console.log('Element is not an HTMLElement', error)
              }
            }} className={`sp-action-menu-item ${option.label == selectedItem ? 'optionsMenuSelected' : '' }`}>            
              <span>
                {typeof option.icon === 'string' ? <i className={`fa ${option.icon}`}></i> : option.icon} 
                {option.label}
              </span>
              {option.label == selectedItem && <CheckIcon style={{ width: '16px' }} />}
          </a>
          ))}
        </div>
      )}
    </div>
  );
}