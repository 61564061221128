import { FC, Ref, useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/layout/app-layout/app-layout";
import Tabs from "../../components/shared/Tabs";
import DataTable from "../../components/shared/DataTable/DataTable";
import { ServiceQuotation } from "../../services/service-quotation";
import { getDaysLeftWithTextV2 } from "../../util";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Modal from "../../components/shared/Modal/Modal";
import { ReactComponent as CircleQuestionIcon } from '../../assets/svg/circleQuestionIcon.svg';
import { ReactComponent as CircleXIcon } from '../../assets/svg/circleXIcon.svg';
import { ReactComponent as CircleCheckIcon } from '../../assets/svg/circleCheckIcon.svg';
import { BidIntentStatus } from "../page-quotation/BidIntentControls/BidIntentControls";
import TooltipContainer from "../../components/shared/TooltipContainer/TooltipContainer";
import Loading from "../../components/shared/Loading";
import ReceivedBidRequestStats from "./ReceivedRequestsStats";
import type { Request } from "./types";
import { NotificationManager } from "react-notifications"; 
import appState from "../../state/AppStateContainer";
import OptionsMenu from "../../components/shared/OptionsMenu/OptionsMenu";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from "../../components/shared/Card/card";
import BtButton from "../bt-button/bt-button";
import serviceSales from "../../services/service-sales";
import type { Subscription } from "../../services/service-sales";
import { formatDate } from '../../util';

type Params = {};
type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
}
const PageBidRequestList : FC<Props> = ({ history }) => {  
  const [selectedTab, setSelectedTab] = useState(0);
  const [requests, setRequests] = useState<Request[]>([]);
  const [addContactModalShow, setAddContactModalShow] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAllRequests, setShowAllRequests] = useState(false);
  const [subscription, setSubscription] = useState<Subscription>();
  const [superadminSubscriptionOverride, setSuperadminSubscriptionOverride] = useState<boolean>();
  const [prioSubscription, setPrioSubscription] = useState<Subscription>();
  const orgSlug = appState._getCurrentOrgSlug();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const prioSubscription = await serviceSales.fetchRecommendedSubscription();
        setPrioSubscription(prioSubscription)
        const response = await ServiceQuotation.fetchAllReceived();
        setRequests(response.data);
        setSubscription(response.subscription);
        if (!!subscription?.active?.startDate || !!prioSubscription?.active?.startDate) {
          setShowAllRequests(true);
        }
      } catch (e) {
        NotificationManager.error((e as Error).toString(), "Kunde inte hämta förfrågningar");
      }
      setLoading(false);
    })();
  }, []);

  const onRowClick = useCallback((_, rowData) => {
    if (rowData.link) {
      window.open(rowData.link);
    } else {
      setSelectedQuotation(rowData.id);
      setAddContactModalShow(true);
    }
    //history.push(`/${orgSlug}/requests/${rowData.id}`);
  }, []);

  const onCreateContactClick = async () => {
    const { link } = await ServiceQuotation.createContactForSalesOrgUserToOpenQuotation(selectedQuotation);
    window.open(link);
    setAddContactModalShow(false);
  }

  const columns = [
    {
      title: "Projekt",
      field: "project",
      render: (rowData: Request) => {
        return (
          <span style={{ color: rowData.isDeleted ? 'var(--gray-500)' : 'var(--gray-900)', fontSize: '16px', fontWeight: 500, textDecoration: rowData.isDeleted ? 'line-through' : '' }}>
            {rowData.project}
          </span>
        );
      },
    },
    {
      title: "Beställare",
      field: "org",
    },
    {
      title: "Yrkeskategori",
      field: "category",
    },
    {
      title: "Version",
      field: "version",
      render: (rowData: Request) => +rowData.version + 1,
    },
    {
      title: "Skickad",
      field: "date",
      render: (rowData: Request) => formatDate(rowData.date),
    },
    {
      title: "Anbudstid",
      field: "timeLeft",
      render: (rowData: Request) => <span style={{ whiteSpace: 'nowrap' }}>{getDaysLeftWithTextV2(rowData.timeLeft)?.daysLeftText}</span>,
    },
    {
      title: "Leveransdatum",
      field: "deliveryStart",
      render: (rowData: Request) => {
        const d1 = formatDate(rowData.deliveryStart);
        const d2 = formatDate(rowData.deliveryEnd);

        if (!d1 && !d2) return '–';

        return [d1, d2].filter(x=>x).join(" – ");
      }
    },
    {
      title: "Mottagare",
      field: "recipients",
      render: (rowData: Request) => rowData.recipients.join(", "),
    },
    {
      title: "Avsikt",
      field: "intent",
      render: (rowData: Request) => {
        const icons = {
          [BidIntentStatus.UNDECIDED]: (className: string, ref: Ref<SVGSVGElement>) => <CircleQuestionIcon fill='var(--gray-700)' width='16px' height='16px' className={className} ref={ref} />,
          [BidIntentStatus.ACCEPTED]: (className: string, ref: Ref<SVGSVGElement>) => <CircleCheckIcon fill='var(--gray-700)' width='16px' height='16px' className={className} ref={ref} />,
          [BidIntentStatus.DECLINED]: (className: string, ref: Ref<SVGSVGElement>) => <CircleXIcon fill='var(--gray-700)' width='16px' height='16px' className={className} ref={ref} />,
        };
        const messages = {
          [BidIntentStatus.UNDECIDED]: "Ingen avsiktsförklaring",
          [BidIntentStatus.ACCEPTED]: "Avser att lämna anbud",
          [BidIntentStatus.DECLINED]: "Avser att inte lämna anbud",
        };
        if (rowData.intent) {
          const intent : BidIntentStatus = rowData.intent;
          return (
            <TooltipContainer className='bidIntentTooltip' renderReferenceComponent={icons[intent]}>
              <div>
                <p>
                  {messages[intent]}
                </p>
              </div>
            </TooltipContainer>
          );
        } else {
          return "–";
        }
      },
    },
    {
      title: "Anbud lämnat",
      field: "answered",
      render: (rowData: Request) => rowData.answered ? "Ja" : "Nej",
    },
    {
      title: "Vunnen",
      field: "won",
      render: (rowData: Request) => rowData.won ? "Ja" : "Nej",
    },
  ];

  const now = new Date();
  const requestsToUser = requests.filter(r => r.recipients.includes(appState.getUserEmail()));
  const inProgressNoBid = (showAllRequests ? requests : requestsToUser).filter((r) => new Date(r.timeLeft) >= now && !r.answered);
  const inProgressHasBid = (showAllRequests ? requests : requestsToUser).filter((r) => new Date(r.timeLeft) >= now && r.answered);
  const expired = (showAllRequests ? requests : requestsToUser).filter((r) => new Date(r.timeLeft) < now);  
  const filtered = selectedTab == 1 ? inProgressNoBid : selectedTab == 2 ? inProgressHasBid : expired;

  let options = ["alla förfrågningar", "mina förfrågningar"].map((o, i) => ({label: o, action: () => setShowAllRequests(i == 0)}));
  let selectedOption = options[showAllRequests ? 0 : 1].label;
  const hasActiveSubscription = ((!!subscription?.active?.startDate || !!prioSubscription?.active?.startDate) && superadminSubscriptionOverride !== false) || superadminSubscriptionOverride;
  if (!hasActiveSubscription) {
    if (selectedTab == 0) {
      options = ["alla förfrågningar"].map((o) => ({label: o, action: () => {}}));
    } else {
      options = ["mina förfrågningar"].map((o) => ({label: o, action: () => {}}));
    }
    selectedOption = options[0].label;          
  }

  return (
    <AppLayout title="Mottagna förfrågningar">
      <div style={{margin: "32px"}}>
        <div className="tab-container">
          <Tabs
            tabs={["Översikt", `Obesvarade (${inProgressNoBid.length})`, `Besvarade (${inProgressHasBid.length})`, `Avslutade (${expired.length})`]}
            selected={selectedTab}
            onChange={tab => { 
              setSelectedTab(tab); 
            }}
          />
          <div className="tab-actions" style={{paddingTop: "16px"}}>
            <OptionsMenu 
              selectedItem={selectedOption} 
              options={options} 
              style={{fontSize: "14px", color: "var(--gray-700)"}}>
                <div>
                  Visa <strong style={{ color: "var(--gray-900)", fontWeight: 500 }}>{selectedOption}</strong> 
                  <ExpandMoreIcon fontSize="small" style={{verticalAlign: "bottom"}}/>
                </div>
            </OptionsMenu>
          </div>
        </div>

        {selectedTab == 0 ? (
          loading ? (
            <Loading type='inline' />
          ) : (<>
            {!hasActiveSubscription && (
              <div style={{ width: "calc(99% - 16px)" }}>
                <Card>
                  <div style={{
                    display: "flex", 
                    justifyContent: "space-between",
                    fontSize: "14px",
                    color: "var(--gray-700)",
                  }}>              
                    <div style={{fontSize: "14px", color: "var(--gray-700)", flexGrow: 1}}>
                      <h3 style={{ fontSize: "18px", color: "var(--gray-900)", fontWeight: 500 }}>Ta kontroll över dina affärsmöjligheter med Accurator Prio – säg adjö till missade förfrågningar.</h3>
                      <p>Tänk dig att du får viktiga förfrågningar via Accurator, men en del av dem hamnar utanför din radar. De skickas kanske till en allmän företagsadress, till en kollega som är på semester eller till nån som inte jobbar kvar. Du missar värdefulla leads och potentiella affärer.</p>
                      <p>Med Accurator Prio får du det där helhetsgreppet som gör skillnad. Alla förfrågningar skickade till ditt företag blir synliga – inte bara de som landar i din egen inkorg. Du kan hantera allt direkt via Accurator.</p>
                      <p><b>Vad betyder det för dig?</b></p>
                      <ul>
                        <li><b>Inga fler förfrågningar missas.</b> Alla leads samlas på ett ställe.</li>
                        <li><b>Maximera din försäljningspotential.</b> Du når fler potentiella kunder och kan stänga fler affärer.</li>
                        <li><b>Effektivisera arbetet.</b> Du slipper administration och vet att ingen förfrågan faller mellan stolarna.</li>
                      </ul>
                      <p><b>Accurator Prio är en investering som ger dig kontroll, effektivitet och ökad lönsamhet!</b></p>
                      <p>Statistiken nedan är baserad på förfrågningar till hela ert företag, men på de andra flikarna visas bara förfrågningar skickade direkt till din e-postadress.</p>
                      
                      <div style={{display:"flex", justifyContent:"center", marginTop: "32px"}}>
                        <BtButton onClick={() => history.push(`/${orgSlug}/marketing`)}>
                          Läs mer och beställ Accuarator Prio på Marknadsföringssidan
                        </BtButton>
                      </div>
                     </div>
                  </div>
                </Card>
              </div>
            )}
            <ReceivedBidRequestStats requests={(showAllRequests || !hasActiveSubscription) ? requests : requestsToUser} />
            {appState.isSuperAdmin() && (
              <Card title="Superadmin" style={{ width: "calc(99% - 16px)" }}>
                <div style={{display:"flex", gap: "12px", fontSize: "12px", color: "var(--gray-700)", alignItems: "center"}}>
                  {superadminSubscriptionOverride === true || superadminSubscriptionOverride === false ? (<>
                    Prenumerationsstatus lokalt ändrad i denna session. Prenumerationen är {superadminSubscriptionOverride ? "på" : "av"}.
                    <BtButton size="xxs" color="white" onClick={() => {
                      setSuperadminSubscriptionOverride(undefined);
                      setShowAllRequests(!!subscription?.active?.startDate || !!prioSubscription?.active?.startDate);
                    }}>
                      Återställ
                    </BtButton>
                  </>) : (<>
                    Ändra prenumerationsstatus lokalt i denna session.
                    <BtButton size="xxs" color="white" onClick={() => {
                      setSuperadminSubscriptionOverride(true);
                      setShowAllRequests(true);
                    }}>
                      Prenumeration på
                    </BtButton>
                    <BtButton size="xxs" color="white" onClick={() => {
                      setSuperadminSubscriptionOverride(false);
                      setShowAllRequests(false);
                    }}>
                      Prenumeration av
                    </BtButton>
                    
                  </>)}
                </div>
              </Card>
            )}
          </>)
        ) : (
          <div>
            <DataTable columns={columns} data={filtered} isLoading={loading} onRowClick={onRowClick} pageSize={10} isScrollable />
          </div>
        )}         
      </div>
      <Modal 
        title="Lägg till kontaktperson?" 
        show={addContactModalShow} 
        setShow={setAddContactModalShow}
        buttonInfo={{label: "Ja", action: onCreateContactClick}}
        cancelButtonText="Nej"
      >
        <div style={{fontSize: "14px", color: "var(--gray-700)", maxWidth: "500px"}}>
          För att öppna denna förfrågan måste du vara kontaktperson för denna leverantör i beställarens leverantörsregister. Vill du läggas till som kontaktperson?
        </div>
      </Modal>
    </AppLayout>
  );
}

export default withRouter(PageBidRequestList);