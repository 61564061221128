import { FC, useEffect, useState } from "react";
import Modal from "../../components/shared/Modal/Modal";
import FeaturedSupplierAdForm from "../app-add-supplier/featured-supplier-ad-form";
import RecommendedAd from "../app-add-supplier/recommended-ad";
import { AdApplication } from "./page-marketing";
import { FeaturedAdInfo, InvoiceInterval, Period } from "../page-bid-request-list/types";
import { AdApplicationType, FileScope } from "../../util/appEnum";
import Selector, { SelectorType } from "../../components/shared/Selector/Selector";
import moment from "moment";
import styles from "./ad-modal.module.scss";
import NewsCard from "../page-dashboard-V2/NewsCard/NewsCard";
import { formatAmount, isValidEmail } from "../../util";
import Badge from "../../components/shared/Badge/Badge";
import _ from "lodash";
import { getImageUrl } from "../../components/shared/ImagePreview/ImagePreview";
import appState from "../../state/AppStateContainer";
import Field from "../../components/shared/Field/Field";

const ProductInvoiceIntervals = {
  [AdApplicationType.RECOMMENDED]: [
    {price: 2999, duration: 1, period: Period.MONTH, cancellationDelay: "P3M"}, 
    {price: 28788, duration: 12, period: Period.MONTH, cancellationDelay: "P0M"},
  ] as InvoiceInterval[],
  [AdApplicationType.FEATURED]: [
    {price: 5000, duration: 1, period: Period.WEEK},
    {price: 9000, duration: 2, period: Period.WEEK},
  ] as InvoiceInterval[],
}
type ProductInvoiceInterval = SelectorType & InvoiceInterval;

const getSwedishPeriod = (period: Period, duration: number) => {
  switch (period) {
    case Period.MONTH:
      return duration === 1 ? 'månad' : `månader`;
    case Period.WEEK:
      return duration === 1 ? 'vecka' : 'veckor';
    case Period.DAY:
      return duration === 1 ? 'dag' : 'dagar';
  }
}
const getSelectorItemInvoiceInterval = (invoiceInterval: InvoiceInterval): ProductInvoiceInterval => {
  const {price, duration, period} = invoiceInterval;
  let label = `${duration} ${getSwedishPeriod(period, duration)}, ${formatAmount(price)} kr`;
  if (duration == 1 && period == Period.MONTH) {
    label = `1 månad, tre månaders uppsägningstid, ${formatAmount(price)} kr/månad`;
  }
  if (duration == 12 && period == Period.MONTH) {
    label = `12 månader, förnyas automatiskt ett år i taget, ${formatAmount(price/12)} kr/månad`;
  }

  return {label, value: label, ...invoiceInterval};
}

const formatDate = (date: Date | undefined) => {
  return date && moment(date).locale('sv').format('D MMMM YYYY');
};

type Props = {
  title: string,
  show: boolean,
  setShow: (x: boolean) => void,
  adApplication: AdApplication,
  setAdApplication: React.Dispatch<React.SetStateAction<AdApplication | undefined>>,
  submitFeaturedSupplierAd: () => Promise<void>,
  submitRecommendedSupplierAd: () => Promise<void>,
  submitting: boolean,
  areas: {id: string, label: string, value: string}[],
  isReviewMode?: boolean,
  setSubmitting: (x: boolean) => void,
}

export const AdModal: FC<Props> = ({
  title,
  show,
  setShow,
  adApplication,
  setAdApplication,
  submitFeaturedSupplierAd,
  submitRecommendedSupplierAd,
  submitting,
  setSubmitting,
  areas,
  isReviewMode,
}) => {
  const [step, setStep] = useState(0);
  const [imageURI, setImageURI] = useState<string | null>();

  const invoiceIntervalOptions: ProductInvoiceInterval[] = adApplication?.applicationType ? ProductInvoiceIntervals[adApplication?.applicationType]?.map(x => getSelectorItemInvoiceInterval(x)) : [];

  const validateFeaturedSupplierAd = () => {
    if (submitting || !adApplication) return false;
    setSubmitting(true);

    const mandatorStringFields: (keyof AdApplication)[] = [
      'startDate',
      'invoiceInterval',
      'title',
      'image',
      'invoiceEmail',
      'invoiceRef',
    ];

    const errors = mandatorStringFields.reduce<{[x: string]: string}>((result, fieldName) => {
      if (!adApplication[fieldName]) {
        result[fieldName] = 'Detta fält är obligatoriskt';
      } else if (fieldName === 'invoiceEmail' && !isValidEmail(adApplication[fieldName])) {
        result[fieldName] = 'Felaktig e-postadress';
      }
      return result;
    }, {});
    setAdApplication(prevState => ({ ...prevState, errors: {} }));

    if (!_.isEmpty(errors) ) {
      setAdApplication(prevState => ({ ...prevState, errors }));
      setSubmitting(false);
      return false;
    }
    setSubmitting(false);
    return true;
  }

  const validateRecommendedSupplierAd = () => {
    if (submitting || !adApplication) return false;

    setSubmitting(true);

    const mandatorStringFields: (keyof AdApplication)[] = [
      'startDate',
      'invoiceInterval',
      'invoiceEmail',
      'invoiceRef',
    ];
    const mandatoryArrayFields: (keyof AdApplication)[] = [
      'regions',
      'areas',
    ];

    const missingString = mandatorStringFields.reduce<{[x: string]: string}>((result, fieldName) => {
      if (!adApplication[fieldName]) {
        result[fieldName] = 'Detta fält är obligatoriskt';
      } else if (fieldName === 'invoiceEmail' && !isValidEmail(adApplication[fieldName])) {
        result[fieldName] = 'Felaktig e-postadress';
      }
      return result;
    }, {});

    const missingArray = mandatoryArrayFields.reduce<{[x: string]: string}>((result, fieldName) => {
      if (_.isEmpty(adApplication[fieldName])) {
        result[fieldName] = 'Detta fält är obligatoriskt';
      }
      return result;
    }, {});
    setAdApplication(prevState => ({ ...prevState, errors: {} }));
    
    const errors = {...missingString, ...missingArray};

    if (!_.isEmpty(errors) ) {
      setAdApplication(prevState => ({ ...prevState, errors }));
      setSubmitting(false);
      return false;
    }
    setSubmitting(false);
    return true;
  }

  useEffect(() => {
    if (adApplication?.image instanceof File) {
      const reader = new FileReader();
      reader.onload = function(ev){
        const img = new Image();
        img.onload = () => {
          if (ev.target?.result) {
            setImageURI(ev.target.result as string);
          }
        }
        img.src = ev.target?.result as string;
      };
      reader.readAsDataURL(adApplication.image);
    } else if (adApplication?.image?.file) {
      setImageURI(getImageUrl(adApplication?.image?.file, appState.getCurrentOrgSlug(), FileScope.FeaturedAdImage, {organizationNumber: adApplication?.organizationNumber}));
    } else {
      setImageURI(undefined);
    }
  }, [adApplication?.image]);

  useEffect(() => {
    if (show) {
      if (isReviewMode) {
        setStep(1);
      } else {
        setStep(0);
      }
    }
  }, [show]);

  useEffect(() => {
    if (isReviewMode) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [isReviewMode]);

  const onClick = () => {
    if (step === 0) {
      const isFeatured = adApplication?.applicationType && adApplication?.applicationType === AdApplicationType.FEATURED;
      const isValid = isFeatured ? validateFeaturedSupplierAd() : validateRecommendedSupplierAd();
      if (isValid) {
        setStep(1);
      }
    } else {
      if (adApplication?.applicationType && adApplication?.applicationType === AdApplicationType.FEATURED) {
        submitFeaturedSupplierAd();
      } else {
        submitRecommendedSupplierAd();
      }
    }
  }

  const selectedInvoiceIntervalOption = adApplication?.invoiceInterval;

  const isPerWeek = adApplication?.invoiceInterval?.period === Period.WEEK;
  const isFeatured = adApplication?.applicationType === AdApplicationType.FEATURED;


  return (
    <Modal
      title={step === 0 ? title : "Beställningsgranskning"}
      show={show}
      setShow={setShow}
      buttonInfo={{
        label: step === 0 ? "Granska beställning" : (isReviewMode ? "Spara" : "Bekräfta och ansök"),
        loaderShow: submitting,
        action: onClick
      }}
      cancelButtonText={step === 1 ? "Tillbaka" : ''}
      onCancelButtonClick={step === 1 ? () => setStep(0) : undefined}
      hasCloseButton
    >
      <div style={{ maxWidth: '550px', display: 'flex', flexDirection: 'column', gap: '16px', color: "var(--gray-700)", fontSize: "14px", lineHeight: "20px" }}>
        {step === 0 && (
          <>
            {adApplication?.applicationType === AdApplicationType.FEATURED && (
              <FeaturedSupplierAdForm
                featuredInfo={(adApplication as FeaturedAdInfo)}
                organizationNumber={adApplication?.organizationNumber || ''}
                onChangeImage={e => setAdApplication(ad => ({ ...ad, image: e.target.files?.[0] }))}
                onRemoveImage={() => setAdApplication(ad => ({ ...ad, image: undefined, }))}
                setState={(getUpdatedState) => setAdApplication((ad) => getUpdatedState({...(ad as FeaturedAdInfo)}))}
              />
            )}
            {adApplication?.applicationType === AdApplicationType.RECOMMENDED && (
              <RecommendedAd
                index={0}
                ad={adApplication}
                areas={areas}
                setState={(getUpdatedState: (x: any) => any) => setAdApplication((ad) => getUpdatedState([{...ad}])[0])}
              />
            )}
            <Selector
              label='Avtalslängd och pris'
              options={invoiceIntervalOptions}
              errorMessage={adApplication?.errors?.invoiceInterval}
              value={adApplication?.invoiceInterval ? getSelectorItemInvoiceInterval(adApplication?.invoiceInterval) : null}
              onChange={invoiceInterval => setAdApplication((ad) => ({ ...ad, invoiceInterval: invoiceInterval ? {price: invoiceInterval?.price, duration: invoiceInterval?.duration, period: invoiceInterval?.period} as InvoiceInterval : undefined, errors: {...ad?.errors, invoiceInterval: ''} }))}
              required
            />
            {(
              !appState.getCurrentOrg()?.invoiceEmail ||
              !appState.getCurrentOrg()?.invoiceRef
            ) && !appState.isSuperAdminOrg() && (
              <>
                <h3 style={{ marginBottom: '0'}}>Fakturauppgifter</h3>
                <p>Vi saknar era fakturauppgifter. Uppgifterna sparas och kommer användas även för framtida köp av tilläggstjänster.</p>
                <Field
                  label='E-postadress'
                  value={adApplication?.invoiceEmail}
                  onChange={invoiceEmail => setAdApplication((ad) => ({ ...ad, invoiceEmail, errors: {...ad?.errors, invoiceEmail: ''} }))}
                  errorMessage={adApplication?.errors?.invoiceEmail}
                  required
                />
                <Field
                  label='Referens'
                  value={adApplication?.invoiceRef}
                  onChange={invoiceRef => setAdApplication((ad) => ({ ...ad, invoiceRef, errors: {...ad?.errors, invoiceRef: ''} }))}
                  errorMessage={adApplication?.errors?.invoiceRef}
                  required
                />
              </>
            )}
          </>
        )}
        {step === 1 && adApplication && (
          <div className={styles.summary}>
            {isFeatured && (
              <div>
                <span style={{ marginBottom: '16px' }}>Förhandsgranskning av annonsen:</span>
                <NewsCard
                  image={imageURI ?? ''}
                  title={adApplication?.title ?? ''}
                  description={adApplication?.description ?? ''}
                  link={adApplication?.url}
                  adPreviewMode
                />
              </div>
            )}
            <span><span>Produkt:</span> <span>{isFeatured ? 'Annons på startsidan' : 'Accurator Prio'}</span></span>
            <div style={{display: "flex", flexDirection: "column", gap: "8px"}}>
              <span><span style={{fontWeight: 500, color: "var(--gray-900)"}}>Fakturauppgifter</span></span>
              <span><span>Adress:</span><span>{adApplication?.invoiceEmail}</span></span>
              <span><span>Er referens:</span><span>{adApplication?.invoiceRef}</span></span>
            </div>
            {!isFeatured && (
              <>
                <span><span>Regioner att rekommenderas på:</span> <span style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', maxWidth: '300px', marginLeft: '8px' }}>{adApplication.regions?.map(x => <Badge key={x?.label}>{x?.label}</Badge>)}</span></span>
                <span><span>Yrkeskategori att bli rekommenderad på:</span> <span style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', maxWidth: '300px', marginLeft: '8px' }}>{adApplication.areas?.map(x => <Badge key={x.label}>{x.label}</Badge>)}</span></span>
              </>
            )}
            {selectedInvoiceIntervalOption && (
              <>
                <div style={{ display: 'grid', gap: "8px" }}>
                  <span><span>Önskat startdatum:</span> <span>{formatDate(adApplication?.startDate)}</span></span>
                  {isPerWeek && <span><span>Föredraget slutdatum (+{selectedInvoiceIntervalOption.duration} {getSwedishPeriod(selectedInvoiceIntervalOption?.period, selectedInvoiceIntervalOption?.duration)}):</span> <span>{formatDate(moment(adApplication?.startDate).add(selectedInvoiceIntervalOption.duration, isPerWeek ? 'weeks' : 'months').toDate())}</span></span>}
                  <span style={{ color: "var(--gray-500)" }}>Det faktiska startdatumet bestäms när annonsen godkänns och kan därför avvika från det önskade.</span>
                </div>
                <span>
                  <span>Period:</span>
                  <span>{`${selectedInvoiceIntervalOption.duration} ${getSwedishPeriod(selectedInvoiceIntervalOption.period, selectedInvoiceIntervalOption.duration)}`}</span>
                </span>
                {selectedInvoiceIntervalOption.cancellationDelay == "P3M" && (
                  <span>
                    <span>Uppsägningstid:</span>
                    <span>3 månader</span>
                  </span>
                )}
                <span>
                  <span>Pris per {isPerWeek ? 'vecka' : 'månad'}:</span>
                  <span style={{ fontWeight: 500 }}>{formatAmount(selectedInvoiceIntervalOption.price / selectedInvoiceIntervalOption.duration)} kr</span>
                </span>

                {adApplication?.rejectionReason && <p style={{ fontSize: '14px', color: 'var(--gray-500)' }}>Skäl för avslag: {adApplication?.rejectionReason}</p>}
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}