import { FC, ReactNode, TransitionEvent, useEffect, useState } from "react";
import './bt-modal.scss';

type Props = {
  name?: string,
  show: boolean,
  center?: boolean,
  white?: boolean,
  onModalHide?: () => void,
  onModalShow?: () => void,
  children: ReactNode,
  isScrollable?: boolean,
}

const BtModal : FC<Props> = ({ name, show, center, white, onModalHide, onModalShow, isScrollable, children }) => {
  name;
  onModalHide;
  onModalShow;

  const [display, setDisplay] = useState(false);
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (show) {
      setDisplay(show);
      setTimeout(() => { setAnimate(show) }, 10);  
    } else {
      setAnimate(false);
    }
  }, [show]);

  const onTransitionEnd = (e: TransitionEvent) => {
    if (!show && e.propertyName === "opacity") {
      setDisplay(false);
    }
  }

  return (
    <div className={[
        "bt-modal",
        white ? 'bt-modal-background-white' : '',
        display ? "bt-modal-show" : "",
        animate ? "bt-modal-animate" : "",
      ].filter(x=>x).join(" ")}
    >
      <div className="bt-modal-background" />
      <div className={`bt-modal-grid${isScrollable ? " scrollable" : ""}`}>
        <div className="bt-modal-row bt-contract">
          <div className="bt-modal-column">
            <div className="slot head" />
          </div>
        </div>
        <div className="bt-modal-row">
          <div 
            className={`bt-modal-column bt-modal-body ${center ? "bt-center" : ""}`} 
            onTransitionEnd={onTransitionEnd}
          >
            <div className="flex-fit">
              <div className="slot">{children}</div>
            </div>
          </div>
        </div>
        <div className="bt-modal-row bt-contract">
          <div className="bt-modal-column">
            <div className="slot foot" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BtModal;
