import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import MaterialTable from 'material-table';
import SearchInput from '../../components/shared/SearchInput';
import { tableIcons } from '../../components/icons/table-icons';
import { serviceOrganization } from '../../services/service-organization';
import { CustomAvatar } from '../../components/shared';
import AddOrganization from './add-organization';
import Pagination from '@mui/material/Pagination';
import { debounce } from 'lodash';
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import './styles.css';
import FiltersDropdown from '../../components/shared/FiltersDropdown/FiltersDropdown';
import AppLayout from '../../components/layout/app-layout/app-layout';
import Modal from '../../components/shared/Modal/Modal';
import BtButton from '../bt-button/bt-button';
import { getDaysLeftWithTextV2 } from '../../util';
import DataTable from '../../components/shared/DataTable/DataTable';
import Tabs from '../../components/shared/Tabs';
import Selector from '../../components/shared/Selector/Selector';
import { withRouter } from 'react-router-dom';
import { serviceSalesOrgApplication } from '../../services/salesOrgApplicationService';

const activeProducts = [
  {
    label: 'Inköp',
    value: 'PURCHASING',
  },
  {
    label: 'Försäljning',
    value: 'SALES',
  },
];

function Organization({ intl, history, location }) {
  const [orgData, setOrgData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showAddOrgModal, setShowAddOrgModal] = useState(false);
  const [addOrgModalKey, setAddOrgModalKey] = useState(0);
  const [editOrgData, setEditOrgData] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [filterSearch, setFilterSearch] = useState({
    searchFilter: '',
    draw: '1',
    start: 0,
    activeProduct: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(0);
  const [applicationCount, setApplicationCount] = useState(0);
  const delayedQuery = useRef(debounce(q => handleChange(q, 'searchFilter'), 500)).current;  

  useEffect(() => {
    if (location.pathname.endsWith("inactive")) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchOrgData();
  }, [selectedTab, currentPage, filterSearch]);

  useEffect(() => {
    if (showAddOrgModal) {
      setAddOrgModalKey(addOrgModalKey + 1);
    }
  }, [showAddOrgModal]);

  useEffect(() => {
    (async () => {
      const response = await serviceSalesOrgApplication.listPendingSignedApplications();
      setApplicationCount(response.length);  
    })();
  }, []);

  const fetchOrgData = async () => {
    setLoader(true);
    const data = await serviceOrganization.getOrganizations({isActive: selectedTab == 0, ...filterSearch });
    setLoader(false);

    if (data) {
      setOrgData(data);
    }
  };

  const handleChange = (value, type) => {
    let filters = {...filterSearch};    
    filters[type] = value;
    if ((type == 'filtersData' && (value.type || value.status)) || type == 'searchFilter' || type == 'activeProduct') {
      filters.start = 0;
      setCurrentPage(1);
    }
    setFilterSearch(filters);
  };

  const getColumns = () => {
    return [
      { title: "Namn", render: row => renderOrgName(row) },
      { title: "Avtalstid", render: row => renderAgreementTime(row) },
      { title: "Status", render: row => renderOrgStatus(row) },
      { title: "Produkter", render: row => renderOrgProducts(row) },
      { title: "Kundtyp", field: 'customerType' },
      {
        title: "Inställningar",
        render: row => (
          <OptionsMenu
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            options={[{ label: 'Redigera', action: () => onEdit(row) }]}
          />
        ),
      },
    ];
  };

  const renderAgreementTime = row => {
    const { expired, daysLeftText } = getDaysLeftWithTextV2(row.expiry);

    if (!row.expiry) {
      return;
    }

    return (
      <div className="ac-generic-td-agreementTime">
        <div>{new Date(row.expiry).toDateString()}</div>
        <div style={{ color: expired ? 'var(--red-700)' : 'var(--green-700)' }}>{daysLeftText}</div>
      </div>
    );
  };

  const renderOrgStatus = row => (
    <div className={`ac-generic-td-status ${row.isActive}`}>
      {row.isActive ? 'Aktiv' : 'Inaktiv'}
    </div>
  );

  const renderOrgProducts = row => {
    return (
      <div style={{ display: 'flex', gap: '8px' }} className="ac-generic-td-status">
        {row?.activeProducts?.includes('PURCHASING') && <span className="badge purchasing">Inköp</span>}
        {row?.activeProducts?.includes('SALES') && <span className="badge sales">Sälj</span>}
      </div>
    )
  }

  const renderOrgName = row => (
    <div className="ac-generic-td-name">
      <CustomAvatar alt={row.name} />
      <div className="td-name-inner-wrapper">
        <div>{row.name}</div>
        <div>{row.email}</div>
      </div>
    </div>
  );

  const onEdit = orgData => {
    setEditOrgData(orgData);
    toggleShowAddOrgModal();
  };

  const toggleShowAddOrgModal = () => {
    setShowAddOrgModal(!showAddOrgModal);
  };

  const saveOrg = async () => {
    await fetchOrgData();
    toggleShowAddOrgModal();
  };

  const onPageChanged = currentPage => {
    setCurrentPage(currentPage);
    const pageOffset = (currentPage - 1) * 10;
    handleChange(pageOffset, 'start');
  };

  const onSearch = e => {
    const searchKey = e.target.value;

    setSearchVal(searchKey);
    delayedQuery(searchKey);
  };

  const onChangeTab = tab => {
    const base = location.pathname.split("/").slice(0, 2).join("/");
    if (tab == 0) history.push(base + "/organizations");
    if (tab == 1) history.push(base + "/organizations/inactive");
    if (tab == 2) history.push(base + "/organizations/applications");
    setSelectedTab(tab);
    onPageChanged(1);
  }

  return (
    <AppLayout title="Organisationer">
      <div style={{ padding: "32px" }}>
        <div className="tab-container">
          <Tabs
            tabs={["Aktiva organisationer", "Inaktiva organisationer", `Ansökningar (${applicationCount})`]}
            selected={selectedTab}
            onChange={onChangeTab}            
          />
          <div className="tab-actions">
            <BtButton onClick={() => {
                setEditOrgData(null);
                toggleShowAddOrgModal();
              }}>
              Lägg till organisation
            </BtButton>
          </div>
        </div>

        <div style={{ display: 'flex', gap: '8px' }} className="ac-generic-table-sub-head">
          <SearchInput placeholder="Sök organisation" value={searchVal} onChange={onSearch} />
          <Selector
            className='activeProductSelectorOrganizationList'
            styles={{ control: (baseStyles) => ({
              ...baseStyles,
              height: '38px'
            }), }}
            isClearable
            isSearchable={false}
            placeholder='Produkter'
            options={activeProducts}
            value={filterSearch?.activeProduct ? activeProducts.find(x => x.value === filterSearch?.activeProduct) : null}
            onChange={(e) => handleChange(e ? e.value : null, 'activeProduct')}
          />
        </div>

        <DataTable 
          isLoading={loader}
          columns={getColumns()}
          data={orgData.data || []}
        />
        
        <div className="sup-pagination org-pagination">
          {orgData.recordsFiltered > 0 && (
            <Pagination
              count={Math.ceil(orgData.recordsFiltered/10)}
              onChange={(_, value) => onPageChanged(value)}
              page={currentPage}
            />
          )}
        </div>

        <AddOrganization
          show={showAddOrgModal}
          onModalHide={toggleShowAddOrgModal}
          intl={intl}
          saveOrg={saveOrg}
          editOrgData={editOrgData}
          key={addOrgModalKey}
        />
      </div>
    </AppLayout>
  );
}

export default withRouter(Organization);