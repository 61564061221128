import React from 'react';
// import PageLogin from "../page-login/page-login"
import PageHvdLogin from '../page-hvd-login/PageHvdLogin';
import PageLogin from '../page-login/page-login';
import appState from '../../state/AppStateContainer';
import './page-home.css';

export default class PageHome extends React.Component {

  render() {

    return (
      <div className="page-home">
        {appState.isTest() ? <PageHvdLogin/> : <PageLogin/> }        
      </div>
    );
  }
}