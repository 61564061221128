import { FC, useEffect, useState } from 'react';
import BtModal from '../../../ts-components/bt-modal/bt-modal';
import BtButton from '../../../ts-components/bt-button/bt-button';
import { CrossSvg } from '../../svgs/cross';
import './Modal.css';

type Props = {
  title: string,
  show: boolean,
  setShow: (value: boolean) => void,
  buttonInfo?: {label: string, action: () => void} & any,
  hasCloseButton?: boolean,
  isScrollable?: boolean,
  cancelButtonText?: string,
  onCancelButtonClick?: () => void,
  unmountWhenHidden?: boolean,
}

const Modal: FC<Props> = ({title, show, setShow, buttonInfo, hasCloseButton, children, isScrollable, cancelButtonText, unmountWhenHidden, onCancelButtonClick}) => {
  const [delayedTransition, setDelayedTransition] = useState(false);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (show) {
      setDelayedTransition(true);
    } else {
      timeoutId = setTimeout(() => {
        setDelayedTransition(false);
      }, 500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [show]);

  let buttonProps = {};
  if (buttonInfo) {
    const { label, action, ...props } = buttonInfo;
    buttonProps = props;
  }

  const buttonContainerShouldRender = !hasCloseButton || (buttonInfo && buttonInfo.action);

  if (unmountWhenHidden && !delayedTransition) {
    return null;
  }

  return (
    <BtModal
      center
      show={show}
      isScrollable={isScrollable}
    >
      <div className='simpleModalContainer'>
        <div className='simpleModalContent' style={isScrollable ? { overflowY: 'auto', maxHeight: "90vh", overflowX: 'hidden' } : {}}>
          {hasCloseButton && (
            <div className='simpleModalCloseButton' onClick={() => setShow(false)}>
              <CrossSvg size='16px' />
            </div>
          )}
          
          <p className='simpleModalTitle'>{title}</p>
          {children}
          
          {buttonContainerShouldRender && (
            <div className='simpleModalButtonContainer'>
              {(!hasCloseButton || cancelButtonText || onCancelButtonClick) && (
                <BtButton class='white' onClick={() => {
                  if (onCancelButtonClick) {
                    onCancelButtonClick();
                  } else {
                    setShow(false);
                  }
                }}>{cancelButtonText || 'Avbryt'}</BtButton>
              )}
              
              {buttonInfo && buttonInfo.action && (
                <BtButton onClick={buttonInfo.action} {...buttonProps}>
                  {buttonInfo.label}
                </BtButton>
              )}
            </div>
          )}
        </div>
      </div>
    </BtModal>
  );
}

export default Modal;