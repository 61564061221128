import moment from "moment";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import inRange from 'lodash/inRange';
import { getUID } from "../global/app";
import DOMPurify from "dompurify";
import appState from "../state/AppStateContainer";
import { SavingState } from "./appEnum";
import * as Sentry from "@sentry/react";
import { formatDistanceToNow as formatDistance, formatDistanceStrict } from "date-fns";
import { sv } from "date-fns/locale";

export function getLocalDateTime(givenDate) {
  const utcDate = givenDate.toString();
  // var gmtDateTime = moment.utc(utcDate, "YYYY-MM-DD HH");
  const gmtDateTime = moment.utc(utcDate);
  const local = gmtDateTime.local().format("YYYY-MMM-DD h:mm A");
  return moment(local, "YYYY-MMM-DD h:mm A").format("lll");
}

export function getDaysLeft(dateEnd) {
  const localizedDate = getLocalDateTime(moment(dateEnd).startOf('day').toDate());
  let parsedDateEnd;
  if (localizedDate == '' || localizedDate == '-' || localizedDate == undefined) {
    parsedDateEnd = '-';
  } else {
    parsedDateEnd = Date.parse(dateEnd)
  }

  if (!parsedDateEnd || parsedDateEnd == '-') {
    return '-';
  }

  var today = new Date();
  var month = today.getMonth() + 1;
  month = month < 10 ? '0' + month : month;

  var day = today.getDate();
  day = day < 10 ? '0' + day : day;

  var date = today.getFullYear() + '-' + month + '-' + day;
  var startDate = Date.parse(date);
  var timeDiff = parsedDateEnd - startDate;
  var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
}

export function getDaysLeftWithText(dateEnd) {
  const daysLeft = getDaysLeft(dateEnd);

  let daysWord = Math.abs(daysLeft) === 1 ? 'dag' : 'dagar';
  if (daysLeft < 0) {
    return `Utgången (${daysLeft} ${daysWord})`
  }

  return `${daysLeft} ${daysWord}`;
}

export function getDaysLeftWithTextV2(dateEnd) {
  const daysLeft = getDaysLeft(dateEnd);

  let daysWord = Math.abs(daysLeft) === 1 ? 'dag' : 'dagar';
  if (daysLeft < 0) {
    return {
      expired: true,
      daysLeftText: `Utgången (${daysLeft} ${daysWord})`,
    }
  }

  return {
    expired: false,
    daysLeftText: `${daysLeft} ${daysWord} kvar`,
  };
}

export function distance(lat1, lon1, lat2, lon2, unit) {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

let hasShown = false;
export function showErrorNotification(message, title = "") {  
  if (!message) {
    message = <>
      <p>Oväntat fel.</p>
      <code style={{fontSize:'9px'}}>{new Error().stack.split("\n").slice(1).join("\n")};</code>
    </>;
  }
  // we make many request at a time
  // making sure we send notification only once
  if (!hasShown) {
    NotificationManager.error(message, title);

    hasShown = true;
  };

  setTimeout(() => {
    hasShown = false;
  }, 0);
}

export function showSuccessNotification(message = "Klart", title = "") {
  // we make many request at a time
  // making sure we send notification only once
    NotificationManager.success(message, title);
}

export function formatPrice(unformattedValue = '') {
  if(unformattedValue){

    const priceString = JSON.stringify(unformattedValue);
    
    // const isPriceValid = /^(?!,$)[\d.]+$/.test(priceString)
  
    // if (!isPriceValid) return priceString;

    let formattedPrice = priceString;
    
    const decimalSeparatedArray = priceString.split('.');
    
    // const hasDecimal = decimalSeparatedArray.length > 1;
    
    const integerValueWithoutDot = new Number(decimalSeparatedArray[0]);
    
    let localeString = integerValueWithoutDot.toLocaleString();
    localeString = localeString.split(',').join('.')
    
    formattedPrice = localeString;
    
    // if (hasDecimal) {
    //   formattedPrice = localeString + ',' + decimalSeparatedArray[1].split(',')[0].slice(0,2);
    // }
    
    return formattedPrice.split('"').join('');
  }
  return "";
}

export function uniqueID() {
  return Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
}

export function formatCurrency(price = '') {
  price = JSON.stringify(price);
  var pp = +price.replace(/\D/g, '');
  var tt = pp.toLocaleString();
  tt = tt.replace(new RegExp(',', 'g'), '.');
  return tt == 0 ? '' : tt;
}

export function areEqual(prevProps, nextProps) {
  return _.isEqual(prevProps, nextProps);
}

export function capitalizeFirstLetter(string = '') {
  if (!string) return '';
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const alphas = ['#', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Å', 'Ä', 'Ö'];

export function getAllNonAlphaAreas(areas = []) {
  const nonAlphaAreas = areas.filter(({ name }) => !alphas.includes(name[0].toUpperCase()));
  return nonAlphaAreas;
}

export function truncateString(string = "", length = 30) {
  if (string.length < length || string.length < 20) return string;
  return `${string.slice(0, length - 3)}...`;
}

export function truncateStringMiddle(string, length = 40) {
  if (string.length < length || string.length < 40) return string;
  return `${string.slice(0, 15)}...${string.slice(string.length - 10)}`;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-ZäöåÄÖÅ\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) ? "" : "Felaktig e-postadress" ;
}

export function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-ZäöåÄÖÅ\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email ? re.test(String(email).trim().toLowerCase()) : false;
}

export function validatePhone(phone) {
  const re = /^((((0{2}?)|(\+){1})46))[\d]{9}$/;
  return re.test(String(phone).toLowerCase()) ? "" : "FELAKTIGT TELEFONNUMMER, ANGE TELEFONNUMMER I DETTA FORMAT +46712345678";
}

export function validateOptionalPhone(phone) {
  // this was valid until Oct 2024: const re = /^\+?[0-9]+(?:[\- ][0-9]+)*$/; // /^\+?[ \-0-9]+$/;
  const re = /^\+?[0-9\- ]+$/;
  return re.test(String(phone).toLowerCase()) ? "" : "Fel telefonnummer";
}

export const getTrimmedFirstString = text => text.split('/')[0].trim();

export const isEmptyEditorState = editorState => {
  const contentState = editorState.getCurrentContent();
  return contentState.getPlainText().trim("").length == 0;
}
export const getOrgSlugFromUrl = () => {
  const orgSlug = window.location.pathname.split("/")[1];
  return orgSlug;
}


const emojiRegex = require('emoji-regex');

const calculateEmojiNumberWithinRange = (emojiPosition, start, end) => {
  // calculate how many emoji appear in a certain range
  let counter = 0;
  emojiPosition.forEach(pos => {
    if (inRange(pos, start, end)) {
      counter++;
    }
  });
  return counter;
};

const calculateNewOffsetLength = (emojiPosition, inline) => {
  // For new offset, the value should be original value + how many Emoji appeared from 0 to offset
  // For new length,
  // the value should be original length + how many Emoji appeared from offset to offset + length
  const newOffset =
    inline.offset + calculateEmojiNumberWithinRange(emojiPosition, 0, inline.offset);
  const newLength =
    inline.length +
    calculateEmojiNumberWithinRange(emojiPosition, inline.offset, inline.offset + inline.length);
  return { offset: newOffset, length: newLength, style: inline.style };
};

const findEmojiPosition = text => {
  const regex = emojiRegex();
  let resultArray = [];
  const returnArray = [];
  let minusCount = 0;
  while ((resultArray = regex.exec(text)) !== null) {
    // If target character is Emoji, push it's index to the returnArray
    // As Emoji is count as 2 index here but in draftjs-to-html it count as 1,
    // therefore need to reduce it's index by how many Emoji appeared before
    returnArray.push(resultArray.index - minusCount);
    minusCount += resultArray[0].length - 1;
  }
  return returnArray;
};

const handleEmojiExtraIndex = entry => {
  // get the Array of position where the Emoji exist
  const emojiPosition = findEmojiPosition(entry.text);
  let { inlineStyleRanges } = entry;
  inlineStyleRanges = inlineStyleRanges.map(inline => {
    // modify all the inlineStyleRanges offset and length one by one
    return calculateNewOffsetLength(emojiPosition, inline);
  });
  return { ...entry, inlineStyleRanges };
};

export const manipulateRawBlocks = rawState => {
  // Loop all the rawState blocks first
  return rawState.blocks.map(entry => {
    return handleEmojiExtraIndex(entry);
  });
};
export const numberValidator = string => /^[+]?\d+$/.test(string);

export const chunkArray = (arr, chunkSize=2) => {
  const result=[];
  for(let i=0; i<arr.length; i+=chunkSize) {
      result.push(arr.slice(i,i+chunkSize));
  }
  return result;
}

export const getFileObject = (file) => {
  const uid = getUID() + '';

  return {
    id: uid,
    fileName: file.name,
    mimeType: file.type,
    fileObj: file,
  }
};

export const humanizeBytes = (bytes) => {
  const i = bytes == 0 ? 0 : Math.floor( Math.log(bytes) / Math.log(1000) );
  return (bytes / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export const formatAmount = (input) => {
  // input: minusSign (optional dash or minus) wholePart (digits and other junk) , (last comma, optional) decimalPart (up to two digits, optional)
  // output: swedish formatted number, with thousand separators and minus sign truncated to two decimals, possibly with trailing comma  
  const price = typeof input === "number" ? input.toString().replace(".", ",") : input.toString();
  const hasMinusSign = price.startsWith("-") || price.startsWith("−");
  const lastComma = price.lastIndexOf(",");
  const wholePart = price.substring(hasMinusSign ? 1 : 0, lastComma != -1 ? lastComma : price.length).replace(/\D/g, "").replace(/^0+/, "");
  const decimalPart = price.substring(lastComma != -1 ? lastComma + 1 : price.length, price.length).replace(/\D/g, "").substring(0,2);
  if (hasMinusSign && wholePart == "" && lastComma == -1 && decimalPart == "") return "−";
  if (hasMinusSign && wholePart == "" && lastComma != -1 && decimalPart == "") return "−0,";
  if (hasMinusSign && wholePart == "" && decimalPart != "") return "−0," + decimalPart;
  var wholePartAsNumber = +((hasMinusSign ? "-" : "") + wholePart);
  var result = wholePartAsNumber.toLocaleString("sv-SE");
  if (lastComma != -1) result += ",";
  result += decimalPart;
  return result == 0 ? '' : result;
}

export const parseAmount = (input) => {
  // input: minusSign (optional dash or minus) wholePart (digits and other junk) , (last comma, optional) decimalPart (up to two digits, optional)
  // output: swedish formatted number, with thousand separators and minus sign truncated to two decimals, possibly with trailing comma
  const price = input.toString();
  const hasMinusSign = price.startsWith("-") || price.startsWith("−");
  const lastComma = price.lastIndexOf(",");
  const wholePart = price.substring(hasMinusSign ? 1 : 0, lastComma != -1 ? lastComma : price.length).replace(/\D/g, "").replace(/^0+/, "");
  const decimalPart = price.substring(lastComma != -1 ? lastComma + 1 : price.length, price.length).replace(/\D/g, "").substring(0,2);
  if (hasMinusSign && wholePart == "" && lastComma == -1 && decimalPart == "") return 0;
  if (hasMinusSign && wholePart == "" && lastComma != -1 && decimalPart == "") return 0;
  if (hasMinusSign && wholePart == "" && decimalPart != "") return parseFloat("−0." + decimalPart);
  const wholePartAsNumber = +((hasMinusSign ? "-" : "") + wholePart);
  // var result = wholePartAsNumber.toLocaleString("sv-SE");
  if (lastComma != -1) {
    return hasMinusSign ? -(wholePart + "." + decimalPart) : +(wholePart + "." + decimalPart);
  } else {
    return wholePartAsNumber;
  }
}

export const htmlToPlainTest = (html) => {
  return DOMPurify.sanitize(html ?? '', {ALLOWED_TAGS: ['#text']})
}

export const weeksToMilliseconds = (value) => {
  if (value === '') return undefined;

  const parsedNumber = parseAmount(value);

  let result;
  if (parsedNumber < 0) {
    result = Math.ceil(parsedNumber);
  } else {
    result = Math.floor(parsedNumber);
  }

  return result * 1000 * 60 * 60 * 24 * 7;
}

export const autosave = async (func) => {
  appState.setAutosavingState(SavingState.SAVING)
  try {
    await func();
    appState.setAutosavingState(SavingState.DONE);
  } catch (error) {
    appState.setAutosavingState(SavingState.HIDDEN);
    const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
    NotificationManager.error(errorMsg, 'Misslyckat');
    Sentry.captureException(error);
  }
}

// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
export const getRandomGenerator = (seedStr) => {
  function cyrb128(str) {
    let h1 = 1779033703, h2 = 3144134277,
        h3 = 1013904242, h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
        k = str.charCodeAt(i);
        h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
        h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
        h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
        h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    h1 ^= (h2 ^ h3 ^ h4), h2 ^= h1, h3 ^= h1, h4 ^= h1;
    return [h1>>>0, h2>>>0, h3>>>0, h4>>>0];
  }

  function sfc32(a, b, c, d) {
    return function() {
      a >>>= 0; b >>>= 0; c >>>= 0; d >>>= 0; 
      var t = (a + b) | 0;
      a = b ^ b >>> 9;
      b = c + (c << 3) | 0;
      c = (c << 21 | c >>> 11);
      d = d + 1 | 0;
      t = t + d | 0;
      c = c + t | 0;
      return (t >>> 0) / 4294967296;
    }
  }

  const seed = cyrb128(seedStr);
  
  return sfc32(seed[0], seed[1], seed[2], seed[3])
}

export const formatDate = (date) => {
  return date && moment(date).locale('sv').format('D MMMM YYYY');
};

export const formatDateTime = (date) => {
  return date && moment(date).locale('sv').format('D MMMM YYYY HH:mm');
};

export const formatDistanceToNow = (date) => {
  return date && +(formatDistanceStrict(date, new Date(), { unit: 'day' }).split(' ')[0]) > 7 ? formatDateTime(date) : formatDistance(date, { addSuffix: true, locale: sv, includeSeconds: true });
}

export const mixColor = (color1, color2, percentageOfColor1) => {
  return `color-mix(in srgb, ${color1} ${percentageOfColor1}, ${color2})`;
}
export const darkenColor = (color, percentage) => {
  return mixColor(color, 'black', percentage);
}