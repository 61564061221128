import BaseService from './baseService';

import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { btSort } from '../utils/bt-array';
import appState from '../state/AppStateContainer';

export class ServiceProfile extends BaseService {
  data$ = new BehaviorSubject([]);
  dataCurrent$ = new BehaviorSubject({});
  storage = [];
  current;

  add(data) {
    this.storage.push(data);
    this.storage.sort((a, b) => {
      const aHasRole = !!a.role;
      const bHasRole = !!b.role;
      if (aHasRole !== bHasRole) {        
        return aHasRole ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });
    this.storage = [...this.storage];
    this.data$.next(this.storage);
  }

  replace(data) {
    this.storage = [...data];
    this.storage.sort((a, b) => {
      const aHasRole = !!a.role;
      const bHasRole = !!b.role;
      if (aHasRole !== bHasRole) {        
        return aHasRole ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });
    this.data$.next(this.storage);
  }

  setCurrent(data) {
    this.current = data;
    this.dataCurrent$.next({ ...data });
  }

  getItemBySlug(slug) {
    const foundItem = this.storage.find(v => {
      if (v.slug == slug) {
        return true;
      } else {
        return false;
      }
    });

    this.setCurrent(foundItem);

    return foundItem;
  }

  async getColleagues() {
    const response = await axios.get('/api/company/users', { headers: { "x-hvd-token": appState.getHvdToken() } });
    this.replace(response.data);

    return this.storage;
  }

  async dbAddMember(data) {
    let orgSlug = appState.getCurrentOrgSlug();
    const response = await axios.post(`/api/company/user`, data, { headers: { "x-hvd-token": appState.getHvdToken() } });

    // Update store data
    // this.add(response.data);

    return response.data;
  }

  async dbUpdateMemberProfile(data) {
   try{
    const formData = new FormData();
    formData.append("json", JSON.stringify(data));
    if (data.image) {
      formData.append("image", data.image);
    }
    
    const response = await axios.put(`/api/user/profile/${data._id}`, formData, { headers: { "x-hvd-token": appState.getHvdToken() } });

    // Replace data
    var foundIndex = this.storage.findIndex((val) => val._id == data._id);
    if(foundIndex > -1) {
        this.storage[foundIndex] = response.data;
        this.replace(this.storage);
    }
    return response;
   } catch(error) {
    return error.response.data
   }
  }

  async dbUpdateMember(data) {
   try{
    const response = await axios.put(`/api/company/user/${data._id}`, data, { headers: { "x-hvd-token": appState.getHvdToken() } });

    // Replace data
    var foundIndex = this.storage.findIndex((val) => val._id == data._id);
    if(foundIndex > -1) {
        this.storage[foundIndex] = response.data;
        this.replace(this.storage);
    }
    return response;
   }
   catch(error){
    return error.response.data
   }
  }

  async registerUser(data) {
    try{
      const formData =  new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const response = await axios.post(`/api/register/user/${data._id}`, formData,{
          headers:{
              "Content-type":"application/x-www-form-urlencoded",
              "accept":"application/x-www-form-urlencoded"
          }
      });
      return response.data.data;
    }
    catch(error){
      return error.response.data
    }
  }
}

export let serviceProfile = new ServiceProfile();
