import { FC } from "react";
import styles from "./AceveBanner.module.scss";


type Props = {}

const AceveBanner : FC<Props> = ({}) => {
  return (
    <div className={styles.card}>
      <div className={styles.container}>
        <p>Accurators ägare, HVD Group, byter namn till Aceve</p>
        <div className={styles.content}>
          Från och med den 12 mars kommer Accurators ägare, HVD Group, att byta namn till Aceve. Det innebär att vi framöver kommer att kallas "Accurator powered by Aceve".
        </div>
        <div className={styles.content}>
          För dig som kund innebär detta fortsatt tillgång till Accurator, precis som vanligt. Du kommer dock att mötas av ett nytt namn och ny logga i våra system, i vår app och på vår hemsida.
        </div>
      </div>
    </div>
  );
}

export default AceveBanner;