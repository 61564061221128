import Logo from "../../components/svgs/logo";
import CraneImage from '../../assets/images/crane.jpg';
import BtBackground from '../bt-background/bt-background';
import { DOMAttributes, useEffect, useState }  from 'react';
import appState from '../../state/AppStateContainer';
import { ServiceAuth } from '../../services/service-auth';
import { AxiosError } from 'axios';
import BtColumn from "../bt-column/bt-column";
import BtGrid from "../bt-grid/bt-grid";
import BtRow from "../bt-row/bt-row";
import BtCenter from "../bt-center/bt-center";

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;
declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['bt-center']: CustomElement<any>;
    }
  }
}

export default function PageSso() {
  const [error, setError] = useState("");
  const [logoutLink, setLogoutLink] = useState("");

  useEffect(() => {
    (async () => {
      const code = new URL(window.location.href).searchParams.get('code');
      if (code) {
        try {
          const data: any = await ServiceAuth.finishMicrosoftSignIn(code);
          if (data && !data.error) {
            await appState.setToken(data, true);
            const urlToRedirect = appState.loadString('urlToRedirect');
            localStorage.removeItem('urlToRedirect');
            window.location.href = urlToRedirect ?? "/";
            return;
          } else if (data && data.error) {
            setError(data.error);
            setLogoutLink(ServiceAuth.getMicrosoftLogoutLink(data.loginHint));
          } else {
            setError("Unknown error");
            setLogoutLink(ServiceAuth.getMicrosoftLogoutLink(null));
          }
        } catch (e) {
          const err = e as AxiosError;
          const data: any = err.response?.data;
          console.log(data)
          if (data?.error) {
            setError(data.error);
            setLogoutLink(ServiceAuth.getMicrosoftLogoutLink(data.loginHint));
          } else {
            setError("Unknown error");
            setLogoutLink(ServiceAuth.getMicrosoftLogoutLink(data.loginHint));
          }
        }
     } else {
       setError("No code");
     }

      appState.setState({loader: false});
    })();
  }, []);

  function renderSsoError(error: string) {
    if (error.startsWith("No organization")) {
      return <>
        <p>Inloggning med Microsoft verkar inte vara aktiverat för din organisation. <a href={logoutLink}>Försök med ett annat konto</a> eller {" "}
        <a href="mailto:support@accurator.se">kontakta Accurator support</a> om felet kvarstår.</p>
        <p>Har ni inte Accurator? <a href="https://accurator.se/">Kontakta oss för att få reda på hur Accurator kan effektivisera era inköp.</a></p>
      </>;
    } else if (error.startsWith("No application role")) {
      return <p>Ditt konto verkar inte ha tillgång till Accurator. Kontakta en administratör eller <a href={logoutLink}>försök med ett annat konto.</a></p>
    } else if (error.startsWith("No code")) {
      return <p>Ingen inloggningskod hittades, <a href="/">gör om inloggningen från början.</a></p>
    } else if (error.startsWith("invalid_grant: 54005")) {
      return <p>Denna inloggningskod är redan använd, <a href="/">gör om inloggningen från början.</a></p>
    } else if (error.startsWith("invalid_grant: 70008")) {
      return <p>Denna inloggningskod är för gammal, <a href="/">gör om inloggningen från början.</a></p>
    } else if (error.startsWith("invalid_grant: 9002313")) {
      return <p>Denna inloggningskod är ogiltig, <a href="/">gör om inloggningen från början.</a></p>
    } else {
      return <>
        <p>Oväntat fel: {error}</p>
        <p>Du kan <a href="/">gå tillbaka till inloggningssidan</a> eller <a href={logoutLink}>logga ut för att försöka med ett annat konto.</a></p>
        <p><a href="mailto:support@accurator.se">Kontakta Accurator Support</a> om felet kvarstår.</p>
      </>
    }
  }

  return <div className="page-login">
    <BtGrid>
        <BtRow>
            <BtColumn className="bt-w50 first-column bg-white">
                <BtCenter>
                  <div className="app-login bt-w60">

                    <Logo size="350px" type="logoWithTitle" />
                    <h2>Inloggningen misslyckades</h2>
                    {renderSsoError(error)}
                  </div>
                </BtCenter>
            </BtColumn>
            <BtColumn className="second-column">
                <BtBackground className="bg-login bt-background-position-left" src={CraneImage} />
            </BtColumn>
        </BtRow>
    </BtGrid>
  </div>;
}