import { FC, useEffect, useState } from 'react';
import Logo from '../../components/svgs/logo';
import CraneImage from '../../assets/images/crane.jpg';
import BtBackground from '../bt-background/bt-background';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtCenter from '../bt-center/bt-center';
import { RouteComponentProps } from 'react-router-dom';
import styles from './page-contract-status.module.scss';
import ContractStatus, { Signatory } from '../app-project-overview-item/ContractStatus';
import { serviceSalesOrgApplication } from '../../services/salesOrgApplicationService';
import { Loading } from '../../components/shared';
import { ContractStatusType } from '../../models/global.model';
import { SalesOrganizationApplicationStatus } from '../page-bid-request-list/types';

type SalesOrgApplication = {
  status: ContractStatusType,
  documentId: string,
  organizationNumber: string,
  recipients: Signatory[],
  orgSlug?: string,
  applicationStatus?: SalesOrganizationApplicationStatus,
}
  
type Params = {
  code: string,
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
  location: RouteTypes['location'];
  match: RouteTypes['match'];
};

export const PageContractStatus: FC<Props> = ({ location, history, match }) => {
  location;
  history;

  const [sendAgain, setSendAgain] = useState({});
  const [salesOrgApplication, setSalesOrgApplication] = useState<SalesOrgApplication>();
  const [loading, setLoading] = useState(true);
  const { code } = match.params;

  useEffect(() => {
    if (code) {
      (async () => {
        const salesOrgApplication =  await serviceSalesOrgApplication.getSalesOrgApplication(code);
        setSalesOrgApplication(salesOrgApplication);
  
        setLoading(false);
      })()
    }
  }, [code]);

  return (
    <div className="page-login">
      <BtGrid>
        <BtRow>
          <BtColumn className="bt-w50 first-column bg-white" style={{ padding: '70px 0' }}>
            <BtCenter>
              <div className="align-center bt-w60">
                <div className="m-t-30 m-b-40 d-flex justify-content-center">
                  <Logo size="350px" type="logoWithTitle" />
                </div>
              {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Loading type='inline' />
                  </div>
                ) : salesOrgApplication && (
                  <>
                    <h3 style={{fontSize: "18px", fontWeight: 500, color: "var(--gray-900)"}}>
                      Du har ansökt om leverantörskonto för {salesOrgApplication.organizationNumber}
                    </h3>
                    <p>Signeringsstatus för användaravtal:</p>
                    <ContractStatus
                      loading={false}
                      signatories={salesOrgApplication.recipients}
                      status={salesOrgApplication.status}
                      sendAgain={sendAgain}
                      setSendAgain={setSendAgain}
                      error={''}
                      documentId={salesOrgApplication.documentId}
                      organizationNumber={salesOrgApplication.organizationNumber}
                    />
                    {salesOrgApplication.orgSlug && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '20px' }}>
                        <span style={{ fontSize: '14px', color: 'var(--gray-700)' }}>Leverantörskontot har skapats och en inbjudan har skickats till din e-post.</span>
                      </div>
                    )}
                    {!salesOrgApplication.orgSlug && salesOrgApplication.applicationStatus === 'SIGNED' && (
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '20px' }}>
                        <span style={{ fontSize: '14px', color: 'var(--gray-700)' }}>Leverantörskontot kunde inte skapas. En organisation med det organisationsnumret fanns redan i Accurator.</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </BtCenter>
          </BtColumn>
          <BtColumn className="second-column">
            <BtBackground
              className={`bg-login bt-background-position-left ${styles.background}`}
              src={CraneImage}
            />
          </BtColumn>
        </BtRow>
      </BtGrid>
    </div>
  );
};