import { BehaviorSubject } from "rxjs";
import { ServiceUserType } from "./service-user-type";
import axios from "axios";
import _ from "lodash";
import BaseService from "./baseService";
import { ServiceAuthController } from './service-auth';
import appState from "../state/AppStateContainer";

export class ServiceUserController extends BaseService {
  async getUsersWithRoles(searchFilter) {
    const response = await axios.get("/api/company/role/users", {
      params: { searchFilter }
    });
    return response.data;
  }

  async getUserById(id) {
    const response = await axios.get(`/api/user/${id}`);
    // if(response.data.imgId)  {
    //   const authService = new ServiceAuthController()
    //   const avatarUrl = authService.getAvatarUrl(response.data.imgId);
    //   response.data.avatarUrl = avatarUrl;
   
    //  }
    return response.data;
  }
  // async getUserAvatarById(id) {
  //  try {
  //   const response = await axios.get(`/api/user/avatar/${id}`);
  //   // if(response.data.imgId)  {
  //   //   const authService = new ServiceAuthController()
  //   //   const avatarUrl = authService.getAvatarUrl(response.data.imgId);
  //   //   response.data.avatarUrl = avatarUrl;
   
  //   //  }
  //    if(response.status === 404 || response.status === 400) return null; 
  //    appState.setAvatarFetchState()
  //   return response.data;
  //  } catch (error) {
  //   appState.setAvatarFetchState()

  //    return;
  //  }
  // }

  async saveProjectUsersAreas(data) {
    const response = await axios.put("/api/company/permission/user", data);
    return response.data;
  }

  async getProjectUsersAreas(id) {
    const response = await axios.get(`/api/company/${id}/user`);
    return response.data;
  }

  async removeUser(id) {
    const response = await axios.delete("/api/company/user", {
      params: { id: id },
      headers: { "x-hvd-token": appState.getHvdToken() }, 
    });
    return response.data;
  }

  async getTasksUsers() {
    const response = await axios.get(`/api/company/users`);
    return response.data;
  }

  async getSignatureById(id) {
    const response = await axios.get(`/api/user/mail_signature/${id}`);
    return response.data;
  }

  async getUserOrganizations(id) {
    const response = await axios.get(`/api/user/organizations/${id}`);
    return response.data;
  }

  async fetchAndSetToken(orgSlug, isOrgChangedToken = false){
  try {
    const response = await axios.post('/api/user/organization/token', { orgSlug, isOrgChangedToken });
 
    return response.data;
  } catch (error) {
    console.log(error)
    return;
    }
  }
  async addMemberToArea(userId, projectId, areaId) {
    const response = await axios.put(`/api/user/${userId}/project/${projectId}/area/${areaId}`);
    return response.data;
  }
  async removeMemberFromArea(userId, projectId, areaId) {
    const response = await axios.delete(`/api/user/${userId}/project/${projectId}/area/${areaId}`);
    return response.data;
  }

  // data$ = new BehaviorSubject({});
  // storage = {};

  // add(data) {
  //   this.storage = data;
  //   this.data$.next(this.storage);
  // }

  // clear() {
  //   this.add({});
  // }
  async hasAccessToSlug(userId, orgSlug) {
    const response = await axios.get(`/api/user/${userId}/hasAccessToOrgSlug/${orgSlug}`);
    return response.data;
  }

  async updateProjectTablePreferences(userId, data) {
    const response = await axios.put(`/api/user/${userId}/updateProjectListPreferences`, data);
    return response.data;
  }
  async updatePreferences(userId, data, preference) {
    const response = await axios.put(`/api/user/${userId}/updateUserPreferences/${preference}`, data);
    return response.data;
  }
  
}

export const ServiceUser = new ServiceUserController();
