import _ from "lodash";
import { FC, useContext, useEffect, useRef, useState } from "react";
import CustomAvatar from "../../components/shared/CustomAvatar";
import appState from '../../state/AppStateContainer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { LogoutIcon, SettingsIcon } from "../../components/svgs";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ServiceAuth } from "../../services/service-auth";
import { BidRequestDraftsContext } from "../../App";
import { darkenColor } from "../../util";
// import { ServiceUser } from "../../services/service-user";
// import appEnum from "../../util/appEnum";
// import Toggle from "react-toggle";

type Params = {
  areaSlug: string;
  project: string;
  version: string;
};

type RouteTypes = RouteComponentProps<Params, Record<string, unknown>>;

type Props = {
  history: RouteTypes['history'];
}



const UserProfileMenu : FC<Props> = ({ history }) => {
  const [, setBidRequestDrafts] = useContext(BidRequestDraftsContext);
  const [showOptions, setShowOptions] = useState(false);
  const menu = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickEvent = (e : MouseEvent) => {
      const wrapperElement = menu.current;
      if (wrapperElement && !wrapperElement.contains(e.target as Node) && showOptions) {
        setShowOptions(false);
      }
    }
    if (showOptions) {
      document.addEventListener('click', handleClickEvent);
    } else {
      document.removeEventListener('click', handleClickEvent);
    }
    return () => document.removeEventListener('click', handleClickEvent);
  }, [showOptions]);


  const orgSlug = appState.getCurrentOrgSlug();
  const userName = () => _.get(appState, 'state.token.name');
  const userEmail = () => _.get(appState, 'state.token.email');
  const onSettings = () => {
    setShowOptions(false);
    history.push(`/${orgSlug}/profile`);
  };
  const onToggleSwitcher = async () => {
    setShowOptions(!showOptions);
  };
  const logoutClickCallback = async () => {
    setShowOptions(false);
    setBidRequestDrafts({});
    await ServiceAuth.logout();
    const root_theme = document.querySelector(':root') as HTMLElement;

    if (root_theme?.style) {
      root_theme.style.setProperty('--primary-button-background-color', '#ff5e15');
      root_theme.style.setProperty('--primary-button-background-color-hover', '#EA580C');
      root_theme.style.setProperty('--primary-button-text', 'white');
      root_theme.style.setProperty('--secondary-button-background-color', 'white');
      root_theme.style.setProperty('--secondary-button-background-color-hover', darkenColor('var(--secondary-button-background-color)', '97%'));
      root_theme.style.setProperty('--secondary-button-text', 'var(--gray-700)');
      root_theme.style.setProperty('--menu-text-color', 'white');
      root_theme.style.setProperty('--menu-text-color-not-focused', darkenColor('var(--menu-text-color)', '90%'));
      root_theme.style.setProperty('--background-image', '');
      root_theme.style.setProperty('--menu-background-color', 'var(--gray-800)');
      root_theme.style.setProperty('--primaryFont', 'Inter');
      appState.setLogoUrl(undefined);
    }
    history.push('/');
  };
  // const toggleListViewBeta = async () => {
  //   await ServiceUser.updatePreferences(
  //     appState.getUserId(), 
  //     { enabled: !appState.getListViewBetaPreference() }, 
  //     appEnum.userPrefrences.listViewBeta
  //   );
  //   await appState.getUserDetails();
  // }
  return <>    
    <div className="organization-switcher" ref={menu}>
      <div className="sub-organization-switcher" style={{ cursor: 'pointer' }}>
        <div onClick={onToggleSwitcher} style={{display:"flex", alignItems:"center"}}>
          <CustomAvatar
            alt={userName()}
            src={appState.getAvatarUrl()}
            className="org-switcher-avatar"
            size={"38px"}
          />

          <ExpandMoreIcon fontSize="small" style={{display: showOptions ? "none" : "inline"}}/>
          <ExpandLessIcon fontSize="small" style={{display: showOptions ? "inline" : "none"}} />
        </div>
      </div>
      <ul id="org-dropdown" className={`avatar-dropdown-options ${showOptions ? 'show' : ''}`} style={{left:"-270px", width: "350px"}}>
        <li className="avatar-dropdown-option main" style={{display: "flex", alignItems: "center", cursor: 'default', backgroundColor: 'var(--white)'}}>
          <div className="pic" style={{textAlign:"center"}}>
            <CustomAvatar
              alt={userName()}
              src={appState.getAvatarUrl()}
              className="org-switcher-avatar"
              size={"70px"}
              style={{border: "1px solid var(--gray-300)"}}
            />
          </div>
          <div>
            <div className="name">{userName()}</div>
            <div className="email">{userEmail()}</div>
          </div>
        </li>
        {/* <li className="avatar-dropdown-option" onClick={toggleListViewBeta}>
          <label className="autoBid-toggle-label" style={{margin: "-4px 0", display:"flex", alignItems:"center", letterSpacing:0, color: "var(--gray-700)", cursor:"pointer"}}>
            <Toggle
              className="ar-checkbox-toggle autobid-switch"
              checked={appState.getListViewBetaPreference()}
              icons={false}
              readOnly
            />
            Ny projektvy (beta)
          </label>          
        </li> */}
        <li className="avatar-dropdown-option iconed" onClick={onSettings}>
          <SettingsIcon />
          Profil och konto
        </li>
        <li className="avatar-dropdown-option iconed" onClick={logoutClickCallback}>
          <LogoutIcon />
          Logga ut
        </li>
      </ul>
    </div>
  </>;
}

export default withRouter(UserProfileMenu);