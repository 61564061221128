import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import Tabs from "../../components/shared/Tabs";
import './page-projects-start.css';
import { btSort } from '../../utils/bt-array';
import BtButton from '../bt-button/bt-button';
import TrashIcon from "../../components/svgs/trashIcon";
import CustomizeIcon from "../../components/svgs/customizeIcon";
import DraggableIndicatorIcon from "../../components/svgs/draggableIndicatorIcon";
import appState from "../../state/AppStateContainer";
import MaterialTable from "material-table";
import appEnum from '../../util/appEnum';
import Selector from '../../components/shared/Selector/Selector';
import services from '../../services';
import { reverse } from 'lodash';
import FiltersDropdown from '../../components/shared/FiltersDropdown/FiltersDropdown';
import { isEmpty } from 'lodash';
import { ServiceProjects } from "../../services/service-projects";
import { ServiceUser } from "../../services/service-user";
import SearchBarWithActions from "../../components/shared/SearchBarWithActions/SearchBarWithActions";
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import CustomToggle from '../../components/shared/CustomToggle';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import Legends from '../../components/shared/Legends/Legends';
import Pagination from '@mui/material/Pagination';

import {ReactComponent as PresentationIcon} from '../../assets/svg/presentationIcon.svg';
import {ReactComponent as StationIcon} from '../../assets/svg/stationIcon.svg';
import {ReactComponent as HealthBuildingIcon} from '../../assets/svg/healthBuildingIcon.svg';
import {ReactComponent as HouseIcon} from '../../assets/svg/houseIcon.svg';
import {ReactComponent as HotelIcon} from '../../assets/svg/hotelIcon.svg';
import {ReactComponent as PowerIcon} from '../../assets/svg/powerIcon.svg';
import {ReactComponent as FactoryIcon} from '../../assets/svg/factoryIcon.svg';
import {ReactComponent as BuildingIcon} from '../../assets/svg/buildingIcon.svg';
import {ReactComponent as RoadIcon} from '../../assets/svg/roadIcon.svg';
import {ReactComponent as PingPongIcon} from '../../assets/svg/pingPongIcon.svg';
import {ReactComponent as MeetingIcon} from '../../assets/svg/meetingIcon.svg';
import {ReactComponent as FootballFieldIcon} from '../../assets/svg/footballFieldIcon.svg';
import {ReactComponent as ShopIcon} from '../../assets/svg/shopIcon.svg';
import {ReactComponent as TunnelIcon} from '../../assets/svg/tunnelIcon.svg';
import {ReactComponent as SchoolBagIcon} from '../../assets/svg/schoolBagIcon.svg';
import {ReactComponent as EmailCheckIcon} from '../../assets/svg/emailCheckIcon.svg';
import AppLayout from "../../components/layout/app-layout/app-layout";
import DataTable from "../../components/shared/DataTable/DataTable";
import CustomizationMenu from "../../components/shared/CustomizationMenu/CustomizationMenu";
import NotificationCounter from "../../components/shared/NotificationCounter/NotificationCounter";

const MAX_CLOSED_PROJECTS = 10;

const projectTypeToIconMap = (props) => ({
  'Kontorsbyggnader': <PresentationIcon {...props} />,
  'Samfärdsel': <StationIcon {...props} />,
  'Sjuk & Hälsovård': <HealthBuildingIcon {...props} />,
  'Gruppbyggda småhus': <HouseIcon {...props} />,
  'Hotell / Restaurang': <HotelIcon {...props} />,
  'Kraft & Belysning': <PowerIcon {...props} />,
  'Industrier / Verkstad / Lager': <FactoryIcon {...props} />,
  'Flerbostadshus': <BuildingIcon {...props} />,
  'Vägar / Gator / Broar / Vatten': <RoadIcon {...props} />,
  'Idrott / Fritidsanläggning': <PingPongIcon {...props} />,
  'Samlingslokaler': <MeetingIcon {...props} />,
  'Idrott Utomhus': <FootballFieldIcon {...props} />,
  'Affärslokaler': <ShopIcon {...props} />,
  'Tunnel': <TunnelIcon {...props} />,
  'Skola / Förskola': <SchoolBagIcon {...props} />,
})

const DEFAULT_COLUMN_CONFIGURATION = [
  {
    name: 'Projekt',
    show: true,
    static: true,
  }, {
    name: 'Projektnummer',
    show: true,
  }, {
    name: 'Typ',
    show: false,
  }, {
    name: 'Kommun',
    show: true,
  }, {
    name: 'Upphandlingar',
    show: true,
  }, {
    name: 'Status',
    show: true,
  }, {
    name: 'Estimerad projektkostnad',
    show: true,
  },
];

const importAll = (r) => {
  let images = {};
  r.keys().forEach(item => { images[item.replace('./', '')] = r(item); });
  return images
}

const images = importAll(require.context('../../assets/images/KommunIcons', false, /\.(png|jpe?g|svg)$/));

export const consolidateColumnConfiguration = (projectListConfig, defaultColumns, saveColumnConfig) => {
  const columnNamesInConfig = projectListConfig.map(x => x.name);
  const defaultColumnNames = defaultColumns.map(x => x.name);
  const newColumns = defaultColumns.filter(x => columnNamesInConfig.indexOf(x.name) === -1);
  const updatedColumns = projectListConfig.filter(x => defaultColumnNames.indexOf(x.name) !== -1); // remove columns which don't exist in default column configuration

  if (newColumns.length > 0 || updatedColumns.length != projectListConfig.length) {
    // Insert each new column after the column it appears after in the default column configuration
    for (const column of newColumns) {
      let prevIndex = defaultColumnNames.indexOf(column.name) - 1;
      let insertIndex;
      if (prevIndex >= 0) {
        let prevName = defaultColumnNames[prevIndex];
        insertIndex = updatedColumns.findIndex(c => c.name === prevName) + 1;  
      } else {
        insertIndex = 0;
      }
      updatedColumns.splice(insertIndex, 0, column);
    }
    saveColumnConfig(updatedColumns);
    return updatedColumns;
  }
  return projectListConfig;
}

export default function PageProjectsStart({ addProject, removeProject, history, clearNotifications, clearAllNotifications }) {
  const getPageLimit = (selectedTab, numberOfProjects) => {
    return selectedTab ? MAX_CLOSED_PROJECTS : numberOfProjects || 1;
  };
  const [projects, setProjects] = useState([]);
  const [selectedProjectTab, setSelectedProjectTab] = useState(0);
  const [openProjects, setOpenProjects] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [kommun, setKommun] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const tableRef = useRef(null);
  const [cities, setCities] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(
    getPageLimit(selectedProjectTab, selectedProjects.length),
  );
  const [isLoading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [isCustomizationOpen, setCustomizationOpen] = useState(false);
  const customizeTableRef = useRef();
  const [totalAreas, setTotalAreas] = useState(0);
  const [filtersData, setFiltersData] = useState({
    projectType: [],
    kommun: [],
  });
  const [showFilter, toggleFilter] = useState(false);
  const getFilteredResult = projectsToFilter => {
    const array = projectsToFilter
      .filter(project => project.name.toUpperCase().includes(searchValue.toUpperCase()))
      .filter(project => (!isEmpty(kommun) ? kommun.includes(project.province) : true))
      .filter(project => (!isEmpty(projectType) ? projectType.includes(project.projectTypeName) : true));

    setTotalAreas(
      array.reduce(
        (prev, curr) => Math.max(prev, curr.stepOneAreas + curr.stepTwoAreas + curr.stepThreeAreas + curr.stepFourAreas),
        0,
      ),
    );

    return array;
  };
  const sortProjects = open => {
    return btSort(
      projects.filter(p => p.isOpen == open),
      'createdAt',
      'desc',
    );
  };
  const saveTableColumns = async (data) => {
    await ServiceUser.updatePreferences(appState.getUserId(),data,appEnum.userPrefrences.projectTable);
  }
  const updateColumns = async (data) => {
    await saveTableColumns(data);
    setColumns(data);
  }
  const setupTableConfiguration = async () => {
    await appState.getUserDetails();
    const projectListConfig = appState.getProjectListPreferences();
    if (projectListConfig) {
      setColumns(consolidateColumnConfiguration(projectListConfig, DEFAULT_COLUMN_CONFIGURATION, saveTableColumns));
    } else {
      setColumns(DEFAULT_COLUMN_CONFIGURATION);
    }
  }

  useEffect(() => {
    setLoading(true);
    (async function () {
      const temp = await services.getCities();
      setCities(temp.map(city => ({ label: city, value: city })));

      const temp2 = await ServiceProjects.getProjectTypes();
      setProjectTypes(temp2.map(pT => ({ label: pT.name, value: pT._id })));

      setProjects(await ServiceProjects.getProjectList());

      await setupTableConfiguration();
      const filtersPreferences = appState.getProjectFilterPreferences();
      setFiltersData((prev)=> ({
        ...prev,
        ...filtersPreferences
      }))
      handleChange(filtersPreferences)
      setLoading(false);
    })();
  }, []);

  // Close customize table options when clicking outside of the div
  useEffect(() => {
    const listener = (event) => {
      if (!customizeTableRef.current || customizeTableRef.current.contains(event.target)) {
        return;
      }
      setCustomizationOpen(false);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [customizeTableRef]);

  useEffect(() => {
    const oProjects = sortProjects(true);
    const cProjects = sortProjects(false);
    setOpenProjects(oProjects);
    setClosedProjects(cProjects);
    setSelectedProjects(getFilteredResult(selectedProjectTab ? cProjects : oProjects));
  }, [projects]);

  useEffect(() => {
    setSelectedProjects(getFilteredResult(selectedProjectTab ? closedProjects : openProjects));
  }, [currentPage, selectedProjectTab, searchValue, kommun, projectType]);

  useEffect(() => {
    setPageLimit(() => {
      const pageLimit = getPageLimit(selectedProjectTab, selectedProjects.length);
      tableRef.current.dataManager.changePageSize(pageLimit);
      return pageLimit;
    });
  }, [selectedProjects]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedProjectTab]);

  const isWriteAccessRole = appState.isWriteAccessRole();

  const getNotificationCount = projectNotifications => {
    if(projectNotifications && projectNotifications > 0){
      return projectNotifications;
    }
    return 0;
  };

  const getColumns = () => {
    const getProjectTypeComponent = (rowData) => (
      <TooltipContainer renderReferenceComponent={(className, ref) =>
        <div ref={ref} className={`projectTypeContainer ${className}`} style={{ visibility: rowData.projectTypeName ? 'visible' : 'hidden' }}>
          {projectTypeToIconMap({width: 18, height: 18})[rowData.projectTypeName]}
        </div>
      }>
        {rowData.projectTypeName}
      </TooltipContainer>
    )

    return [...columns.filter(column => column.show || column.static).map(column => {
      switch (column.name) {
        case 'Projekt':
          return {
            title: 'Projekt',
            field: 'name',
            render: (rowData) => (
              <div className='projectColumn'>
                <div>
                  <div>{getProjectTypeComponent(rowData)}</div>
                  <span style={{ color: 'var(--gray-900)', fontSize: '16px' }}>{rowData.name}</span>
                </div>
                <div><NotificationCounter counter={getNotificationCount(rowData.notification)} /></div>
              </div>
            ),
            cellStyle: {
              width: '400px'
            },
            customSort: (a, b) => {
              const aName = a?.name ?? "";
              const bName = b?.name ?? "";
              return aName.localeCompare(bName, undefined, { numeric: true });
            },
          };
        case 'Projektnummer':
          return {
            title: 'Projektnummer',
            field: 'number',
            customSort: (a, b) => {
              const an = a?.number*1;
              const bn = b?.number*1;
              if (isNaN(an) && !isNaN(bn)) return 1;
              if (!isNaN(an) && isNaN(bn)) return -1;
              if (!isNaN(an) && !isNaN(bn)) {
                return an < bn ? -1 : (an === bn ? 0 : 1); 
              } else {
                const aa = a?.number ?? "";
                const bb = b?.number ?? "";  
                return aa.localeCompare(bb, undefined, { numeric: true });
              }
            },
          };
        case 'Typ':
          return {
            title: 'Typ',
            field: 'projectTypeName',
            cellStyle: { width: 'unset' },
            width: '100px',
          };
        case 'Kommun':
          return {
            title: 'Kommun',
            field: 'province',
            render: (rowData) => {
              let normalizedKommunName;
              let kommunIcon;
              if (rowData.province) {
                normalizedKommunName = rowData.province.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '-');
                kommunIcon = images[`${normalizedKommunName}.png`];
              }
              
              return (
                <div style={{ display: 'flex', gap: '10px' }}>
                  {rowData.province && <img width={'17px'} height={'20px'} src={kommunIcon} />}
                  <span>{rowData.province || '-'}</span>
                </div>
              )
            },
            cellStyle: { width: 'unset' },
          }
        case 'Upphandlingar':
          const numberOfAreas = (rowData) => rowData.stepOneAreas + rowData.stepTwoAreas + rowData.stepThreeAreas + rowData.stepFourAreas;
          return {
            title: 'Upphandlingar',
            customSort: (a, b) => numberOfAreas(a) - numberOfAreas(b),
            render: (rowData) => {
              const totalProjectAreas = numberOfAreas(rowData);
              return <strong style={{ fontSize: '14px'}}>{totalProjectAreas}</strong>
            },
            cellStyle: { width: 'unset' },
            align: 'left'
          }
        case 'Status':
          return {
            title: 'Status',
            sorting: false,
            render: (rowData) => {
              const totalProjectAreas = rowData.stepOneAreas + rowData.stepTwoAreas + rowData.stepThreeAreas + rowData.stepFourAreas;
              return totalProjectAreas > 0 && (
                <div className='areaDisplayContainer'>
                  {rowData.stepOneAreas > 0 && (
                    <div className='barContainer' style={{ width: `${(rowData.stepOneAreas/totalProjectAreas) * 100}%` }}>
                      <div className='barOne' />
                      <span>{rowData.stepOneAreas}</span>
                    </div>
                  )}
                  {rowData.stepTwoAreas > 0 && (
                    <div className='barContainer' style={{ width: `${(rowData.stepTwoAreas/totalProjectAreas) * 100}%` }}>
                      <div className='barTwo' />
                      <span>{rowData.stepTwoAreas}</span>
                    </div>
                  )}
                  {rowData.stepThreeAreas > 0 && (
                    <div className='barContainer' style={{ width: `${(rowData.stepThreeAreas/totalProjectAreas) * 100}%` }}>
                      <div className='barThree' />
                      <span>{rowData.stepThreeAreas}</span>
                    </div>
                  )}
                  {rowData?.stepFourAreas > 0 && (
                    <div className='barContainer' style={{ width: `${(rowData?.stepFourAreas/totalProjectAreas) * 100}%` }}>
                      <div className='barFour' />
                      <span>{rowData?.stepFourAreas}</span>
                    </div>
                  )}
                </div>
              )
            },
            cellStyle: { width: 'unset' },
          }
        case 'Estimerad projektkostnad':
          const estimatedCostSortKey = (rowData) => rowData.estimatedCost ? rowData.estimatedCost.split(/\D/)[0] * 1 : -1;
          return {
            title: 'Estimerad projektkostnad',
            field: 'estimatedCost',
            customSort: (a, b) => estimatedCostSortKey(a) - estimatedCostSortKey(b), 
            render: (rowData) => <p>{`${rowData.estimatedCost} MSEK`}</p>,
            emptyValue: '-',
            cellStyle: { width: '250px' },
          }
      }
    }), {
      title: '',
      align: 'center',
      render: (rowData) => isWriteAccessRole && (
        <div onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          {!isLoading && <OptionsMenu style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }} options={[
            {
              label: 'Ta bort projekt', action: async () => {
                const isProjectRemoved = await removeProject(rowData._id, rowData.name, rowData.slug);
                if (isProjectRemoved) {
                  setProjects(oldProjects => oldProjects.filter(oldProject => oldProject._id != rowData._id))
                }
              }, 
              icon: <TrashIcon color='var(--gray-700)' size={"16px"}/>
            },
            {
              label: 'Markera alla meddelanden som lästa', action: async () => {
                const clearedNotifications = await clearNotifications(rowData._id, rowData.name);
                if (clearedNotifications) {
                  setProjects(oldProjects => oldProjects.map(oldProject => 
                    oldProject._id == rowData._id ? { ...oldProject, notification: 0 } : oldProject
                  ))
                }
              }, 
              icon: <EmailCheckIcon color='var(--gray-700)' />
            },
          ]} />}
        </div>
      
      ),
      cellStyle: {
        width: 'unset'
      },
      width: '30px'
    }];
  }

  const legends = useMemo(
    () => [
      {
        color: 'var(--darkBlue)',
        label: 'Förfrågan',
      },
      {
        color: 'var(--sunglowYellow)',
        label: 'Utvärdering',
      },
      {
        color: 'var(--crayolaGreen)',
        label: 'Förhandling',
      },
      {
        color: 'var(--gray-500)',
        label: 'Avtal',
      },
    ],
    [],
  );

  const getCurrentPageOfProjects = projects => {
    return projects
      .slice(currentPage * pageLimit - pageLimit, currentPage * pageLimit)
      .map(project => {
        let foundCost = appEnum
          .getProjectCostDataSource()
          .find(id => id.key == project.estimatedCost);
        return { ...project, estimatedCost: foundCost && foundCost.value };
      });
  };
  const handleChange = value => {
    setKommun(value.kommun);
    setProjectType(value.projectType);
  };
  const onSubmitFilter = useCallback((filtersData) => handleChange(filtersData), []);

  const onCancelFilter = useCallback(() => {
    toggleFilter(!showFilter);
    let resetFilter = { kommun: [], projectType: [] };
    setFiltersData(resetFilter);
    handleChange(resetFilter);
  }, [showFilter, filtersData]);

  const checkboxes = () => {
    return [
      {
        label: 'Kommun',
        name: 'kommun',
        value: filtersData.kommun ? filtersData.kommun : cities[0] ? cities[0].value : '',
        subChecks: cities.map(city => ({
          label: city.label,
          name: 'kommun',
          value: city.value,
        })),
      },
      {
        label: 'Projekttyp',
        name: 'projectType',
        value: filtersData.projectType
          ? filtersData.projectType
          : projectTypes[0] && projectTypes[0].label,
        subChecks: projectTypes.map(pt => ({
          label: pt.label,
          name: 'projectType',
          value: pt.label,
        })),
      },
    ];
  };
  const filterCheckboxes = useMemo(() => checkboxes(), [cities, projectTypes, filtersData]);


  const tableColumns = getColumns();
  const numberOfPages = Math.ceil(selectedProjects.length/pageLimit);
  return (
    <AppLayout title="Projekt">
    <div className="pageProjectStartContainer">
      <div className="tab-container">
        <Tabs
          tabs={['Pågående Projekt', 'Avslutade Projekt']}
          selected={selectedProjectTab}
          onChange={setSelectedProjectTab}
        />
        {isWriteAccessRole && (
          <div className="tab-actions">
            <BtButton onClick={async () => {
                const clearedNotifications = await clearAllNotifications();
                if (clearedNotifications) {
                  setProjects(oldProjects => oldProjects.map(oldProject => ({ ...oldProject, notification: 0 })));
                }
              }} color="white">Rensa aviseringar</BtButton>
            <BtButton onClick={addProject}>Nytt projekt</BtButton>
          </div>
        )}
      </div>

      <SearchBarWithActions value={searchValue} onChange={setSearchValue}>
        <FiltersDropdown
          onSubmitFilter={onSubmitFilter}
          onResetFilter={onCancelFilter}
          checkboxes={filterCheckboxes}
          showFilter={showFilter}
          toggleFilter={toggleFilter}
          filtersData={filtersData}
          multiple
        />
        <CustomizationMenu columns={columns} setColumns={updateColumns} />
      </SearchBarWithActions>
      <Legends legendsArray={legends} />

      {tableColumns && tableColumns.length > 0 && (
        <DataTable
          tableRef={tableRef}
          isLoading={isLoading}
          columns={getColumns()}
          data={getCurrentPageOfProjects(selectedProjects)}
          onRowClick={(e, rowData) => {
            const orgSlug = appState._getCurrentOrgUrl();
            history.push(`${orgSlug}/projects/${rowData.slug}`)
          }}
        />
      )}
      
      {numberOfPages > 1 && (
        <Pagination
          count={numberOfPages}
          onChange={(_, value) => setCurrentPage(value)}
          page={currentPage}
          style={{ alignSelf: 'center', marginTop: '25px' }}
        />
      )}
    </div>
    </AppLayout>
  );
}
