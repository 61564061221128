import { Component, createRef } from 'react';
import configHelper from '../../util/configHelper';
import { formatDate } from '../../util';
import { serviceAreas } from '../../services/service-areas';
import { ServiceSupplier } from '../../services/service-supplier';
import services from '../../services';
import { regions } from "../../staticData/regions";

import { debounce } from 'lodash';
import MaterialTable from 'material-table';
import Select, { components, createFilter } from 'react-select';
import { NotificationManager } from 'react-notifications';
import { SearchIcon } from '../../components/svgs';
import BtModal from '../../ts-components/bt-modal/bt-modal';
import AppAddArea from '../app-add-area/app-add-area';
import AppAddEditSupplier from '../app-add-supplier/app-add-supplier';
import SupplierDetailPanel from './supplier-detail-panel';
import { tableIcons } from './icons';
import TableTopActions from './supplier-database-top-actions';
import RiskIndicator from './riskIndicator';
import DatePicker from 'react-datepicker';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EventIcon from '@material-ui/icons/Event';
import BlockIcon from '@material-ui/icons/Block';

import './dataTable.min.css';
import './app-supplier-database.css';
import './supplier-database-mini.css';
import serviceCreditSafe from '../../services/service-creditSafe';
import { ServiceProjects } from '../../services/service-projects';
import moment from 'moment';
import _ from 'lodash';
import SelectedSupplierModule from './selected-supplier-module';
import OptionsMenu from '../../components/shared/OptionsMenu/OptionsMenu';
import appState from '../../state/AppStateContainer';
import SearchBarWithActions from '../../components/shared/SearchBarWithActions/SearchBarWithActions';
import Selector from '../../components/shared/Selector/Selector';
import TooltipContainer from '../../components/shared/TooltipContainer/TooltipContainer';
import ContractIcon from '../../components/svgs/contractIcon';
import StyledCheckbox from '../../components/shared/StyledCheckbox/StyledCheckbox';
import StarRating from '../../components/shared/Rating';
import Field from '../../components/shared/Field/Field';
import BackButton from '../../components/shared/BackButton/BackButton';
import Pagination from '@mui/material/Pagination';
import OverflowTip from '../../components/shared/OverflowTip/OverflowTip';
import SupplierBlockedReasons from './supplier-blocked-reasons';
import AppLayout from '../../components/layout/app-layout/app-layout';
import SupplierAdPopUp from './SupplierAdPopUp/SupplierAdPopUp';
import { ServiceDashboard } from '../../services/service-dashboard';
import { AdApplicationStatus, AdApplicationType, PrequalificationDecision } from '../../util/appEnum';
import Modal from '../../components/shared/Modal/Modal';
import { serviceAdApplication } from '../../services/service-ad-application';
import { AdModal } from '../page-marketing/ad-modal';
import * as Sentry from "@sentry/react";
import BlockSupplierModal from './BlockSupplierModal';
import serviceSupplierMonitoring from "../../services/service-supplier-monitoring";
import { ReactComponent as MonitoringIcon } from "../../assets/svg/monitor-icon.svg";
import CentralAgreementIcon from '../../components/svgs/centralAgreementIcon';
import RecategorizeModal from './recategorizeModal';

export const TabNames = Object.freeze({
  RECOMMENDED: "RECOMMENDED",
  FEATURED: "FEATURED",
  AD_APPLICATIONS: "AD_APPLICATIONS",
});

const AdTypesText = {
  [AdApplicationType.RECOMMENDED]: 'Rekommenderad leverantör',
  [AdApplicationType.FEATURED]: 'Annons på startsidan',
};

const AdStatusText = {
  [AdApplicationStatus.PENDING]: 'Pågående',
  [AdApplicationStatus.REJECTED]: 'Nekade',
}

const getSelectedTabName = (selectMode, selectedTab, isFeaturedSupplier) => {
  const isSuperAdminOrg = appState.isSuperAdminOrg();
  if (selectMode && selectedTab !== 0) {
    return TabNames.RECOMMENDED;
  }
  if (isFeaturedSupplier && selectedTab === 3) {
    return TabNames.FEATURED;
  }
  if (isSuperAdminOrg && selectedTab === 3) {
    return TabNames.AD_APPLICATIONS;
  }
  return [
    PrequalificationDecision.APPROVED,
    PrequalificationDecision.INBOX,
    PrequalificationDecision.REJECTED
  ][selectedTab];
}

const featuredOptions = [
  { value: 1, label: 'Ja' },
  { value: 2, label: 'Nej' },
]
export default class SupplierDatabase extends Component {
  pagemounted = true;
  constructor(props) {
    super(props);
    this.moveQueryParamToState();
    this.state = {
      ratingsDropdown: [
        { value: 1, label: <>{this.rating(1, true, true)}</> },
        { value: 2, label: <>{this.rating(2, true, true)}</> },
        { value: 3, label: <>{this.rating(3, true, true)}</> },
        { value: 4, label: <>{this.rating(4, true, true)}</> },
        { value: 5, label: <>{this.rating(5, true, true)}</> },
        { value: 'not rated yet', label: 'Inget betyg' },
      ],
      filterSearch: {
        searchFilter: props?.history?.location?.state?.q || '',
        areaId: props.areaUid ? { value: props.areaUid, label: props.areaName } : '',
        municipality: '',
        rating: '',
        order: undefined,
        columns: [
          { data: '0', name: 'name' },
          { data: '1', name: 'area' },
          { data: '2', name: 'municipality' },
        ],
        draw: '1',
        start: 0,
        project: props.selectMode ? props.selectMode : false,
      },
      areas: [],
      cities: [],
      data: [],
      isLoading: false,
      addUpdateSupplier: false,
      modalAddAreaShown: false,
      modalAddAreaKey: 0,
      modalAddAreaDataLoading: false,
      modalRecategorizeShow: false,
      modalRecategorizeKey: 0,
      // selectedSupplierIds: [...(props.selectedSupplierIds || [])],
      selectedSupplierIds: [],
      editSupplierData: [],
      quoteVersionsData: [],
      quoteVersion: null,
      extendedDate: undefined,
      contactPersons: [],
      selectedSuppliers: [],
      currentPage: 1,
      errorMsg: '',
      addEditSupplierModalKey: 0,
      selectedTab: 0,
      numberOfRecommendedSuppliers: 0,
      showAdNotificationModal: false,
      showAdModal: false,
      submittingAd: false,
      selectedAdIndex: -1,
      blockModal: {
        show: false,
        key: 0,
        block: true,
        rowData: {},
      },
      rejectionReasonModalVisibility: false,
      rejectionReason: '',
    };
    this.tableRef = createRef();
    this.debouncedSearch = debounce(this.fetchSearchData, 1000);
  }
  componentWillUnmount() {

    this.pagemounted = false;
  }
  componentDidMount() {
    const selectedTab = this.state.selectedTab;
    let filterData = this.state.filterSearch;
    const { areaUid, modifySuppName } = this.props;

    const isFeatured = this.props.history?.location?.pathname?.split('/')?.[3] === 'featured';
    const supplierId = this.props.history?.location?.pathname?.split('/')?.[4];

    const isFeaturedSupplier = isFeatured && supplierId;

    const selectedTabName = getSelectedTabName(this.props.selectMode, selectedTab, isFeaturedSupplier);

    if (selectedTabName !== TabNames.AD_APPLICATIONS) {
      filterData.order = [{ column: '0', dir: 'asc' }];
      this.setState({ filterSearch: filterData });
    }

    if (modifySuppName) {
      this.handleChange(modifySuppName, 'searchFilter');
    } else {
      if (areaUid) filterData.areaId = areaUid;

      if (isFeaturedSupplier) {
        this.setState({ selectedTab: 3 });
        ServiceDashboard.getFeaturedSupplier(supplierId).then(featuredSupplier => {
          this.setState({ data: { data: [{ ...featuredSupplier }], page: 0, totalCount: 1, selectedTab: 3 } });
        });
      } else {
        this.fetchSuppliers(filterData);
      }
    }

    this.fetchAdditionalData();
  }

  componentDidUpdate(prevProps) {
    const queryChanged = this.moveQueryParamToState();
    const q = this.props?.history?.location?.state?.q;
    if (q && queryChanged) {
      this.search(q);
      this.setState({ selectedTab: 0 });
    }
  }

  moveQueryParamToState = () => {
    const search = this.props?.history?.location?.search;
    if (search) {
      const pairs = search.substring(1).split("&").map(s => s.split("="));
      for (const pair of pairs) {
        if (pair[0] == "q") {
          this.props.history.replace(this.props.history.location.pathname, { q: pair[1] });
          return true;
        }
      }
    }
    return false;
  }

  fetchAdditionalData = () => {
    this.fetchCities();
    this.fetchAreas().then(() => {
      if (this.props.selectMode && this.props?.currentProject?.province) {
        ServiceSupplier.getRecommendedSuppliers(this.props.areaUid, this.props?.currentProject?.province).then(recommendedSuppliers => {
          this.setState({ numberOfRecommendedSuppliers: recommendedSuppliers?.length, showAdNotificationModal: this.props.selectMode && !appState.state.token.preferences.supplierAds?.preventPopUp && recommendedSuppliers?.length > 0 });
        });
      }
    });
    this.fetchQuotationVersion();
  };

  fetchQuotationVersion = async () => {
    const { realAreaId, selectMode, currentProject } = this.props;
    if (selectMode) {
      const quoteVersionsData = await ServiceProjects.getVersionData(currentProject.slug, realAreaId);
      let versions = (quoteVersionsData[0] && quoteVersionsData[0].versions) || [];
      if (versions.length) {
        versions = versions.map(v => ({
          ...v,
          value: v.refVersion,
          label: `Version ${v.refVersion + 1}`,
        }));
        if (quoteVersionsData[0]) quoteVersionsData[0].versions = versions;
      }
      if (this.pagemounted) this.setState({ quoteVersionsData });
    }
  };

  rating = (rating, noNumber, readOnly) => (
    <StarRating className="rating-component" rating={rating} noNumber={noNumber} readOnly={readOnly} />
  );

  resetFilter = () => {
    this.setState({
      filterSearch: {
        searchFilter: '',
        areaId: '',
        municipality: '',
        rating: '',
        order: [{ column: '0', dir: 'asc' }],
        featured: '',
        columns: [
          { data: '0', name: 'name' },
          { data: '1', name: 'area' },
          { data: '2', name: 'municipality' },
        ],
      },
      selectedSupplierIds: [],
    });
  };

  getSupplierIds = () => this.state.selectedSupplierIds;

  toggleSort = colNum => {
    const { filterSearch } = this.state;
    const order = filterSearch.order?.[0] || {};
    if (order.column !== colNum) {
      order.column = colNum;
      order.dir = 'asc';
    } else {
      order.dir = order.dir === 'asc' ? 'desc' : 'asc';
    }
    this.handleChange([order], 'order');
  };

  async handleSupplierRowClick(rowData, selectedIds = this.state.selectedSupplierIds || []) {
    const { selectMode } = this.props;
    if (!rowData) return;



    const index = selectedIds.findIndex(s => s == rowData.sup_id);
    let newSupplierIds = [...selectedIds];
    let selectedSuppliers = [...this.state.selectedSuppliers];
    let contactPersons = [...this.state.contactPersons];

    if (index <= -1) {
      selectedSuppliers.push(rowData);
      newSupplierIds.push(rowData.sup_id);
      // add default contact person      
      if (rowData.supMember && rowData.supMember[0] && !contactPersons.find(cp => cp.supplierId === rowData.sup_id)) {
        const { supplierId, _id } = rowData.supMember[0];
        contactPersons.push({
          isCalled: false,
          isSeen: false,
          isSubmit: false,
          supplierId,
          supplierMemberId: _id,
          supplierMember: rowData.supMember[0],
        });
      }
    } else {
      newSupplierIds.splice(index, 1);
      selectedSuppliers.splice(index, 1);
      // remove contact person
      contactPersons = contactPersons.filter(cp => cp.supplierId !== rowData.sup_id);
    }

    this.setState({ selectedSupplierIds: newSupplierIds, selectedSuppliers, contactPersons });
    if (this.props.selectEvent) this.props.selectEvent(newSupplierIds);
  }

  handleChange = (value, type, togglePage, argFilters) => {
    if (value == null) value = '';
    if (value == undefined && (type == 'municipality' || type == 'areaId' || type == 'county' || type == 'featured')) return;
    let { filterSearch } = this.state;
    const { selectMode } = this.props;
    let filters = argFilters ? argFilters : filterSearch;

    if (type === 'selectedTab') {
      this.setState({ selectedTab: value });
    } else if (type !== 'searchFilter') {
      filters[type] = value;
      this.setState({
        filterSearch: filters,
      });
    }

    let filterData = {
      searchFilter: filters.searchFilter,
      areaId: filters.areaId,
      municipality: filters.municipality,
      county: filters.county,
      project: selectMode ? selectMode : false,
      rating: filters.rating,
      order: filters.order,
      columns: filters.columns,
      start: filters.start,
      blocked: filters.blocked,
      featured: filters.featured,
      adStatus: filters.adStatus,
      adType: filters.adType,
    };

    if (togglePage) {
      this.setState({
        currentPage: 1
      });
      filterData.start = 0;
    }

    this.fetchSuppliers(filterData);
  };

  fetchAreas = async (_areasData) => {
    const areas = [];
    const areasData = _areasData ? _areasData : await serviceAreas.getAreas();
    areasData.forEach(item => areas.push({ label: item.name, value: item._id }));

    if (this.pagemounted) this.setState({ areas });
  };

  fetchCities = async () => {
    await services.getCities().then(data => {
      let cities = [];
      data.forEach(item => cities.push({ label: item, value: item }));
      if (this.pagemounted) this.setState({ cities });
    });
  };

  toggleLoader = () => { if (this.pagemounted) this.setState({ isLoading: !this.state.isLoading }); }

  fetchAdApplications = async ({ status, type, search, sort, start }) => {
    const { areas } = this.state;
    const { data: adApplications, size } = await serviceAdApplication.fetchAdApplications({ status, type, search, sort, start });
    this.setState({
      data: {
        data: adApplications.map(x => ({
          ...x,
          ...x.featuredInfo,
          ...x.application,
          ...x.recommendedInfo,
          areas: x.recommendedInfo.areas.map(area => areas.find(_area => _area.value == area)),
          regions: x.recommendedInfo.regions.map(region => regions.find(_region => region === _region.label)),
          startDate: new Date(x.applicationType === AdApplicationType.RECOMMENDED ? x.recommendedInfo.startDate : x.featuredInfo.startDate)
        })), page: 0, totalCount: size
      }
    })
  }

  fetchSuppliers = async (sup) => {
    const { selectedTab } = this.state;
    this.toggleLoader();
    sup.mini = true;

    const isFeaturedSupplier = (() => {
      const isFeatured = this.props.history?.location?.pathname?.split('/')?.[3] === 'featured';
      const supplierId = this.props.history?.location?.pathname?.split('/')?.[4];

      return isFeatured && supplierId;
    })();

    const selectedTabName = getSelectedTabName(this.props.selectMode, selectedTab, isFeaturedSupplier);

    if (selectedTabName !== TabNames.RECOMMENDED) {
      const supplierId = this.props.history?.location?.pathname?.split('/')?.[4];

      if (selectedTabName === TabNames.FEATURED) {
        const featuredSupplier = await ServiceDashboard.getFeaturedSupplier(supplierId);
        this.setState({ data: { data: [{ ...featuredSupplier }], page: 0, totalCount: 1 } });
      } else if (selectedTabName === TabNames.AD_APPLICATIONS) {
        await this.fetchAdApplications({
          status: sup.adStatus,
          type: sup.adType,
          search: sup.searchFilter,
          sort: sup.order?.[0]?.column ? { column: sup.order[0].column, order: sup.order[0].dir } : undefined,
          start: sup.start,
        });
      } else {
        const data = await ServiceSupplier.fetchSupplier({
          ...sup, prequalification: [
            PrequalificationDecision.APPROVED,
            PrequalificationDecision.INBOX,
            PrequalificationDecision.REJECTED
          ][selectedTab]
        });

        if (data.data) {
          let _data = { data: data.data, page: 0, totalCount: data.recordsFiltered };
          if (this.pagemounted) this.setState({ data: _data });
          appState.setSupplierApplicationCount(data.inboxCount);
        }
      }
    } else {
      const recommendedSuppliers = await ServiceSupplier.getRecommendedSuppliers(this.props.areaUid, this.props.currentProject?.province);
      this.setState({ data: { data: recommendedSuppliers.map(supplier => ({ ...supplier, isRecommended: true })), page: 0, totalCount: recommendedSuppliers.length } });
    }

    this.toggleLoader();
  };

  // *******EDIT SUPPLIER****************

  handleEdit = id => {
    const supplierProfile = this.state?.data?.data?.find(s => s.sup_id == id)?.supplierProfile;
    ServiceSupplier.getSupplieById(id).then(data => {
      this.updateEditSupplierData({ ...data, supplierProfile });
      this.setState(({ addEditSupplierModalKey }) => ({ addUpdateSupplier: true, addEditSupplierModalKey: addEditSupplierModalKey + 1 }));
    });
  };

  handleBlock = rowData => {
    this.setState({
      blockModal: {
        show: true,
        key: this.state.blockModal.key + 1,
        block: true,
        rowData,
      }
    });
  }

  handleUnblock = rowData => {
    this.setState({
      blockModal: {
        show: true,
        key: this.state.blockModal.key + 1,
        block: false,
        rowData,
      }
    });
  }

  updateEditSupplierData(data = []) {
    let formData = {
      name: '',
      supplierId: '',
      areaIds: [],
      organizationNumber: '',
      description: '',
    };

    if (data.length != 0) {
      formData.kommun = { label: data.kommun, value: data.kommun };
      formData.name = data.name;
      formData.city = data.city;
      formData.organizationNumber = data.organizationNumber;
      formData.vatNumber = data.vatNumber;
      formData.selectedIdentityField = data.selectedIdentityField;
      formData.website = data.website;
      formData.address = data.address;
      formData.isBlacklist = data.isBlacklist ? data.isBlacklist : false;
      formData.blacklistReason = data.blacklistReason ? data.blacklistReason : [];
      formData.description = data.description;
      formData.address = data.address;
      formData.postnumber = data.postnumber;
      formData.supplierId = data._id;
      formData.frameworkAgreement = data.frameworkAgreement;
      formData.liabilityInsurance = data.liabilityInsurance;
      formData.ue2021Form = data.ue2021Form;
      formData.otherFiles = data.otherFiles;
      formData.logo = data.logo;
      formData.ads = data.ads;
      formData.isFeatured = data.isFeatured;
      formData.featuredInfo = data.featuredInfo;
      let areaIds = [];
      data.areaIds.forEach(function (row, key) {
        areaIds.push(row._id);
      });
      formData.areaIds = areaIds;
      formData.supplierProfile = data.supplierProfile;
    }

    this.setState({ editSupplierData: formData });
  }

  // ***************************************

  handleDelete = supplierId => {
    const { data } = this.state;
    if (window.confirm('Vill du ta bort leverantören?')) {
      ServiceSupplier.deleteSupplier(supplierId).then(() => {
        let supIndex = data.data.findIndex(s => s.sup_id === supplierId);
        if (supIndex > -1) {
          data.data.splice(supIndex, 1);
          this.setState({ data });
        }
      });
    }
  };

  handleMonitoring = async (supplierId, organizationNumber, isMonitored) => {
    try {
      if (isMonitored) {
        await serviceSupplierMonitoring.removeMonitoredSuppliers([organizationNumber,]);
      } else {
        await serviceSupplierMonitoring.addMonitoredEntity({ supplierId, });
      }
      this.fetchSuppliers(this.state.filterSearch);
      NotificationManager.success("Leverantören bevakas", "Sparat");
    } catch (error) {
      console.error(error);
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    }
  }

  handleApprove = async supplierId => {
    await ServiceSupplier.approveSupplierApplication(supplierId);
    NotificationManager.success("Glöm inte att meddela leverantören de goda nyheterna.", "Ansökan godkänd")
    this.fetchSuppliers(this.state.filterSearch);
  }

  handleReject = async supplierId => {
    await ServiceSupplier.rejectSupplierApplication(supplierId);
    NotificationManager.success("Leverantören kan inte ansöka igen så länge ansökan ligger kvar under nekade ansökningar.", "Ansökan nekad")
    this.fetchSuppliers(this.state.filterSearch);
  }

  handleReprocess = async supplierId => {
    await ServiceSupplier.reprocessSupplierApplication(supplierId);
    NotificationManager.success("Leverantören flyttades till fliken ansökningar", "Ansökan omstartad")
    this.fetchSuppliers(this.state.filterSearch);
  }

  renderActions = (supId, rowData) => {
    const isReadOnlyUser = appState.isReadOnlyAccessRole();
    const selectedTabName = getSelectedTabName(this.props.selectMode, this.state.selectedTab);
    if (isReadOnlyUser || selectedTabName == TabNames.RECOMMENDED) return;

    const editSup = () => this.handleEdit(supId);
    const deleteSup = () => this.handleDelete(supId);

    const options = [
      { label: 'Redigera', action: editSup, icon: 'fa-edit' },
    ];

    if (appState.isAdminRole()) {
      if (selectedTabName == PrequalificationDecision.APPROVED && rowData.organizationNumber) {
        options.push({ label: !rowData.isMonitored ? "Bevaka leverantör" : "Sluta bevaka leverantör", action: () => this.handleMonitoring(supId, rowData.organizationNumber, rowData.isMonitored), icon: <MonitoringIcon className='sup-db-opt-menu-monitoring-icon' style={{ marginRight: '8px' }} /> });
      }

      if (rowData.isBlacklist) {
        options.push({ label: "Ta bort spärr", action: () => this.handleUnblock(rowData), icon: 'fa-ban' })
      } else {
        options.push({ label: "Spärra leverantör", action: () => this.handleBlock(rowData), icon: 'fa-ban' })
      }
      options.push({ label: 'Radera', action: deleteSup, icon: 'fa-trash' });

      if (selectedTabName == PrequalificationDecision.INBOX) {
        options.push({ label: "Godkänn ansökan", action: () => this.handleApprove(supId), icon: 'fa-thumbs-up' });
        options.push({ label: "Neka ansökan", action: () => this.handleReject(supId), icon: 'fa-thumbs-down' });
      }
      if (selectedTabName == PrequalificationDecision.REJECTED || selectedTabName == PrequalificationDecision.APPROVED) {
        if (rowData?.application) {
          options.push({ label: "Gör om ansökan", action: () => this.handleReprocess(supId), icon: 'fa-redo' });
        }
      }
    }

    return (
      <OptionsMenu options={options} />
    );
  };

  fetchSearchData = input => {
    this.handleChange(input, 'searchFilter');
  };

  search = (externalSearch) => {
    const searchKey = externalSearch;
    let filterData = this.state.filterSearch;
    filterData['searchFilter'] = searchKey;

    this.setState({
      currentPage: 1
    });
    filterData.start = 0;

    this.setState({ filterData });
    this.debouncedSearch(searchKey);
  };

  resetExceptSearch = () => {
    const filterData = this.state.filterSearch;
    filterData.areaId = '';
    filterData.municipality = '';
    filterData.rating = '';
    filterData.featured = '';
    this.setState({ filterData });
  };

  saveSupplier = (result, check) => {
    const { data, selectedSuppliers, contactPersons } = this.state;
    if (result) {
      const i = data && data.data && data.data.findIndex(s => s.sup_name === result.name);
      if (i > -1) {
        data.data[i].reasons = result.blacklistReason;
        if (selectedSuppliers?.length > 0) {
          const indexOfSelectedSupplierBeingEdited = selectedSuppliers.findIndex(s => s.sup_id === data.data[i].sup_id);
          if (selectedSuppliers[indexOfSelectedSupplierBeingEdited]) {
            selectedSuppliers[indexOfSelectedSupplierBeingEdited].supMember = result.contacts;
          }
        }
        if (contactPersons?.length > 0) {
          const index = contactPersons.findIndex(contact => contact.supplierId === data.data[i].sup_id);
          if (contactPersons[index]) {
            const foundNewContact = result.contacts.find(contact => contact._id === contactPersons[index].supplierMemberId);
            if (!foundNewContact) {
              const newContact = result.contacts[0];
              contactPersons[index].supplierMemberId = newContact._id;
              contactPersons[index].supplierMember = newContact;
            } else {
              contactPersons[index].supplierMember = foundNewContact;
            }
          }
        }
        this.setState({ data, selectedSuppliers, contactPersons });
      }
    }
    if (!check) {
      this.search(result.name);
      this.resetExceptSearch();
      this.toggleAddSupplierModal();
      if (result?.prequalification?.decision == PrequalificationDecision.INBOX) {
        this.setState({ selectedTab: 1 });
      }
    }
  };

  toggleAddSupplierModal = () =>
    this.setState(({ addUpdateSupplier, addEditSupplierModalKey }) => ({ addUpdateSupplier: !addUpdateSupplier, addEditSupplierModalKey: addEditSupplierModalKey + 1 }));

  onAddSupplier = () => {
    this.updateEditSupplierData();
    this.toggleAddSupplierModal();
  };

  // ========================== Add Area Start =============================

  showAddAreaModal = () => {
    this.setState({
      modalAddAreaShown: true,
      modalAddAreaKey: this.state.modalAddAreaKey + 1,
    });
  }

  handleAddAreaWithSuppliers = async (name, suppliersToAdd = []) => {
    let _data = this.state.data;
    let areas;
    this.setState({ modalAddAreaDataLoading: true });

    try {
      areas = await serviceAreas.addArea({ name });
      if (suppliersToAdd.length) {
        const area = areas.find(ar => ar.name === name);

        const promises = suppliersToAdd.map(supplierId => {
          let index = _data && _data.data.findIndex(s => s.sup_id === supplierId);
          if (index > -1) {
            _data.data[index].area = _data.data[index].area + ', ' + name;
          }
          return ServiceSupplier.addSupplierArea({ area: area._id, supplierId });
        });

        await Promise.all(promises);
      }
      await this.handleAddAreaWithSuppliersSuccess();
      this.setState({ modalAddAreaShown: false });
    } catch (error) {
      NotificationManager.error(`något gick fel`);
    } finally {
      // this.fetchAreas();
      if (this.pagemounted) this.setState({ modalAddAreaDataLoading: false, data: _data });
    }
  };

  handleAddAreaWithSuppliersSuccess = async () => {
    NotificationManager.success(`Yrkeskategori tillagd`);
    // reload areas
    await this.fetchAreas();
    // reload mini datatable and close the modal
    if (this.appAddAreaInstance && this.pagemounted) this.appAddAreaInstance.resetState();
  };

  // ========================= Add Area End ================================

  customHeader = (title, colNum) => {
    const { SortArrow } = tableIcons;
    const isActive = this.state.filterSearch.order?.[0]?.column === colNum;
    const titleClass = isActive ? 'sorted' : '';

    return (
      <div onClick={() => this.toggleSort(colNum)} className={`sup-db-title ${titleClass}`}>
        {title}
        {isActive && <SortArrow />}
      </div>
    );
  };

  onPageChanged = currentPage => {
    this.setState({
      currentPage
    })
    const pageOffset = (currentPage - 1) * 10;
    this.handleChange(pageOffset, 'start');
  };

  handleRowClick = (event, rowData, togglePanel) => {
    togglePanel();
  };

  onSelectionChange = (event, rowData) => {
    const { selectMode } = this.props;

    if (selectMode) {
      if (rowData.isBlacklist) return;
      this.handleSupplierRowClick(rowData);
    }
  };

  getColumns = (isFeaturedSupplier) => {
    const { selectedTab } = this.state;

    let columns = [
      {
        title: this.customHeader('Företagsnamn', '0'),
        sorting: true,
        cellStyle: { fontSize: null },
        render: rowData => (
          <div className="sp-first-col">
            {rowData.isBlacklist && (
              <span className="blacklistWarningHover">
                <TooltipContainer renderReferenceComponent={(className, ref) => <BlockIcon style={{ marginRight: '10px' }} className={className} innerRef={ref} />}>
                  {rowData.reasons && rowData.reasons.length > 0 && (
                    <SupplierBlockedReasons reasons={rowData.reasons} />
                  )}
                </TooltipContainer>
              </span>
            )}
            {rowData.frameworkAgreement && (rowData.frameworkAgreement.file || rowData.frameworkAgreement.validUntilDate || rowData.frameworkAgreement.comment) &&
              <ContractIcon style={{ marginRight: '10px', marginLeft: '0', flexShrink: 0 }} />
            }
            {(rowData.centralAgreements && rowData.centralAgreements.length > 0) &&
              <TooltipContainer renderReferenceComponent={
                (className, ref) => <CentralAgreementIcon color="var(--orange-500)" style={{ marginRight: '10px', marginLeft: '0', flexShrink: 0 }} className={className} innerRef={ref} />
              }>
                <div style={{ maxWidth: "300px", fontSize: "14px", color: "var(--gray-700)", fontWeight: 400 }}>
                  Detta centralt upphandlade ramavtal tillhandahålls av Accurator i samarbete med vår partner Procava.
                </div>
              </TooltipContainer>
            }
            <div className="sup-db-tab-val" title={rowData.sup_name}>
              {rowData.sup_name}
            </div>
          </div>
        ),
      },

      {
        title: this.customHeader('Yrkesområde', '1'),
        cellStyle: { fontSize: null },
        render: rowData => <OverflowTip content={rowData.areaArray.map(a => a.name)} />
      },

      {
        title: this.customHeader('Kommun', '2'),
        cellStyle: { fontSize: null },
        field: 'city',
      },
      {
        title: 'Rekommendationer',
        cellStyle: { fontSize: null },
        render: row => {
          return this.rating(row.rating ?? 0, false, true);
        },
        hidden: selectedTab !== 0
      },

      {
        title: 'Antal Projekt',
        cellStyle: { fontSize: null },
        render: rowData => <div className='atal-projekt'>
          <p>
            {rowData.projectsCount || 0}
          </p>
        </div>,
        align: 'center',
        hidden: selectedTab !== 0
      },

      {
        disableClick: true,
        title: <div>Utvärdering</div>,
        cellStyle: { fontSize: null },
        render: rowData => {
          const { selectMode } = this.props;
          const supId = rowData.sup_id;
          const link = `${appState._getCurrentOrgUrl()}/rating/${supId}/true`;

          return (
            <div className="sp-action-wrapper">
              <div className="sp-action-num-icon">
                {rowData.projectsCount && (
                  <a data-id={supId} href={link} target="_self" className="erfaSupplier">
                    <i className="fa fa-paper-plane" />
                  </a>
                )}
              </div>
              {this.renderActions(supId, rowData)}
            </div>
          );
        },
        hidden: selectedTab !== 0
      }
    ];

    let isAdApplicationTab;
    if (!this.props.selectMode && appState.isSuperAdminOrg()) {
      if (isFeaturedSupplier) {
        isAdApplicationTab = selectedTab === 4;
      } else {
        isAdApplicationTab = selectedTab === 3;
      }
    } else {
      isAdApplicationTab = false;
    }

    if (isAdApplicationTab) {
      columns = [
        {
          title: this.customHeader('Organisationsnummer', '0'),
          cellStyle: { fontSize: null },
          render: (rowData) => <span>{rowData.organizationNumber}</span>,
        },
        {
          title: this.customHeader('Typ', '1'),
          cellStyle: { fontSize: null },
          render: (rowData) => <span>{AdTypesText[rowData.applicationType]}</span>,
        },
        {
          title: this.customHeader('Status', '2'),
          cellStyle: { fontSize: null },
          render: (rowData) => <span>{AdStatusText[rowData.status]}</span>,
        },
        {
          title: this.customHeader('Ansökningsdatum', '3'),
          cellStyle: { fontSize: null },
          render: (rowData) => <span>{formatDate(rowData.createdAt)}</span>,
        },
        {
          title: '',
          cellStyle: { fontSize: null },
          render: (rowData) => {
            const options = [];
            if (rowData.status === AdApplicationStatus.PENDING) {
              options.push({
                label: "Godkänn ansökan", action: () => {
                  this.updateAdStatus(rowData._id, AdApplicationStatus.APPROVED);
                }, icon: 'fa-thumbs-up'
              });
              options.push({
                label: "Neka ansökan", action: () => {
                  this.setState({ rejectionReasonModalVisibility: true, selectedAdIndex: rowData.tableData.id });
                }, icon: 'fa-thumbs-down'
              });
            }
            if (rowData.status === AdApplicationStatus.REJECTED) {
              options.push({
                label: "Gör om ansökan", action: () => {
                  this.updateAdStatus(rowData._id, AdApplicationStatus.PENDING);
                }, icon: 'fa-redo'
              });
            }
            options.push({
              label: 'Radera', action: async () => {
                await serviceAdApplication.deleteAdApplication(rowData._id);
                await this.fetchAdApplications({
                  status: this.state.filterSearch.adStatus,
                  type: this.state.filterSearch.adType,
                  search: this.state.filterSearch.searchFilter,
                  sort: this.state.filterSearch.order?.[0]?.column ? { column: this.state.filterSearch.order[0].column, order: this.state.filterSearch.order[0].dir } : undefined,
                  start: this.state.filterSearch.start,
                });
              }, icon: 'fa-trash'
            });

            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div onClick={e => e.stopPropagation()}>
                  <OptionsMenu
                    options={options}
                  />
                </div>
              </div>
            )
          }
        }
      ];
    }

    return columns;
  };

  handleQuoteExpiry = expDate => {
    const { quoteVersion } = this.state;
    let extendedDate = new Date(expDate);
    quoteVersion.quotationExpiryDate = extendedDate;
    this.setState({ extendedDate, errorMsg: '' });
  };

  onToggleQuotation = quotationToggle => {
    let extendedDate = this.state.extendedDate;
    let quoteVersion = null;
    let contactPersons = this.state.contactPersons;
    if (quotationToggle) {
      const { quoteVersionsData = [] } = this.state;
      const quoteVersions = _.cloneDeep(quoteVersionsData[0] && quoteVersionsData[0].versions);
      contactPersons = this.initiateDefaultContactPerson();
      quoteVersion = quoteVersions[quoteVersions.length - 1];
      extendedDate = extendedDate || (quoteVersion.quotationExpiryDate ? new Date(quoteVersion.quotationExpiryDate) : null);
    }
    this.setState({ quoteVersion, contactPersons, extendedDate });
  };

  initiateDefaultContactPerson = () => {
    const { contactPersons, selectedSuppliers } = this.state;
    let contactPersonsArray = contactPersons;
    if (selectedSuppliers && selectedSuppliers.length) {
      selectedSuppliers.map(sup => {
        if (sup.supMember && sup.supMember[0] && !contactPersonsArray.find(cp => cp.supplierId === sup.sup_id)) {
          const { supplierId, _id } = sup.supMember[0];
          contactPersonsArray.push({
            isCalled: false,
            isSeen: false,
            isSubmit: false,
            supplierId,
            supplierMemberId: _id,
            supplierMember: sup.supMember[0],
          });
        }
      });
    }
    return contactPersonsArray.filter(cp => selectedSuppliers.find(supplier => supplier.sup_id === cp.supplierId));
  };

  isDateExpired = endDate => moment.utc().isAfter(endDate);

  checkQuotationExpiry = (expDate = null) => {
    const { quoteVersionsData } = this.state;
    const quoteVersions = quoteVersionsData[0] && quoteVersionsData[0].versions;
    let latestQuoteVersions =
      quoteVersions && quoteVersions.length && quoteVersions[quoteVersions.length - 1];
    return latestQuoteVersions && this.isDateExpired(expDate || latestQuoteVersions.quotationExpiryDate);
  };

  onSelectContactPerson = cp => {
    const { contactPersons } = this.state;
    const cpIdx = contactPersons.findIndex(c => c.supplierId === cp.supplierId) || 0;
    let cpData = {
      isCalled: false,
      isSeen: false,
      isSubmit: false,
      supplierId: cp.supplierId,
      supplierMemberId: cp._id,
      supplierMember: cp,
    };

    if (cpIdx > -1) contactPersons[cpIdx] = cpData;
    else contactPersons.push(cpData);
    this.setState({ contactPersons });
  };

  updateAdStatus = async (id, status, rejectionReason) => {
    if (!id) return;
    this.setState({ submittingAd: true });

    const data = { status };

    if (status === AdApplicationStatus.REJECTED) {
      data.rejectionReason = rejectionReason;
    }

    try {
      await serviceAdApplication.updateAdApplicationStatus(id, data);
      await this.fetchAdApplications({
        status: this.state.filterSearch.adStatus,
        type: this.state.filterSearch.adType,
        search: this.state.filterSearch.searchFilter,
        sort: this.state.filterSearch.order?.[0]?.column ? { column: this.state.filterSearch.order[0].column, order: this.state.filterSearch.order[0].dir } : undefined,
        start: this.state.filterSearch.start,
      });
      this.setState({ showAdModal: false, selectedAdIndex: -1, rejectionReason: '', rejectionReasonModalVisibility: false, });
      NotificationManager.success('Ansökan har lämnats', 'Ansökt');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      this.setState({ submittingAd: false });
    }
  }

  submitRecommendedSupplierAd = async (adApplication) => {
    try {
      await serviceAdApplication.updateRecommendedAdApplication(adApplication?._id, {
        ad: {
          startDate: adApplication?.startDate,
          regions: (adApplication?.regions ?? []).map(x => x?.value ?? '').filter(x => x),
          areas: (adApplication?.areas ?? []).map(x => x?.value ?? '').filter(x => x),
        },
        invoiceInterval: adApplication?.invoiceInterval,
        invoiceEmail: adApplication?.invoiceEmail,
        invoiceRef: adApplication?.invoiceRef,
      });
      this.setState({ showAdModal: false });
      await this.fetchAdApplications({
        status: this.state.filterSearch.adStatus,
        type: this.state.filterSearch.adType,
        search: this.state.filterSearch.searchFilter,
        sort: this.state.filterSearch.order?.[0]?.column ? { column: this.state.filterSearch.order[0].column, order: this.state.filterSearch.order[0].dir } : undefined,
        start: this.state.filterSearch.start,
      });

      NotificationManager.success('Ansökan har lämnats', 'Ansökt');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      this.setState({ submittingAd: false });
    }
  };

  submitFeaturedSupplierAd = async (adApplication) => {
    this.setState({ submittingAd: true });
    try {
      await serviceAdApplication.updateFeaturedAdApplication(adApplication?._id, {
        featuredInfo: {
          startDate: adApplication?.startDate,
          url: adApplication?.url,
          title: adApplication?.title,
          image: adApplication?.image,
          description: adApplication?.description,
        },
        invoiceInterval: adApplication?.invoiceInterval,
        invoiceEmail: adApplication?.invoiceEmail,
        invoiceRef: adApplication?.invoiceRef,
      });
      this.setState({ showAdModal: false });
      await this.fetchAdApplications({
        status: this.state.filterSearch.adStatus,
        type: this.state.filterSearch.adType,
        search: this.state.filterSearch.searchFilter,
        sort: this.state.filterSearch.order?.[0]?.column ? { column: this.state.filterSearch.order[0].column, order: this.state.filterSearch.order[0].dir } : undefined,
        start: this.state.filterSearch.start,
      });
      NotificationManager.success('Ansökan har lämnats', 'Ansökt');
    } catch (error) {
      const errorMsg = _.get(error, 'response.data.error', 'Oväntat fel (' + error + ')');
      NotificationManager.error(errorMsg, 'Misslyckat');
      Sentry.captureException(error);
    } finally {
      this.setState({ submittingAd: false });
    }
  };

  render() {
    const { selectMode, realArea, currentProject } = this.props;
    const {
      data,
      ratingsDropdown,
      cities,
      areas,
      isLoading,
      addUpdateSupplier,
      editSupplierData,
      modalAddAreaShown,
      modalAddAreaKey,
      modalAddAreaDataLoading,
      filterSearch,
      selectedSupplierIds,
      contactPersons,
      selectedSuppliers,
      quoteVersionsData,
      extendedDate,
      quoteVersion,
      errorMsg,
      addEditSupplierModalKey,
      selectedTab,
      numberOfRecommendedSuppliers,
      showAdNotificationModal,
      inboxCount,
      showAdModal,
      submittingAd,
      selectedAdIndex,
    } = this.state;

    const quoteVersions = quoteVersionsData[0] && quoteVersionsData[0].versions;
    const isFeaturedSupplier = (() => {
      const isFeatured = this.props.history?.location?.pathname?.split('/')?.[3] === 'featured';
      const supplierId = this.props.history?.location?.pathname?.split('/')?.[4];

      return isFeatured && supplierId;
    })()
    const selectedTabName = getSelectedTabName(selectMode, selectedTab, isFeaturedSupplier);

    // Set isChecked for the checkbox inside the detailsPanel
    if (data.data && selectedTabName !== TabNames.AD_APPLICATIONS) {
      data.data.forEach(x => {
        x.isChecked = selectedSupplierIds && selectedSupplierIds.findIndex(s => s === x.sup_id) > -1;
      })
    }

    const supplierApplicationForm = window.location.href.split("/").slice(0, 4).join("/") + "/new-supplier";


    const adApplicationStatusSelectorOptions = Object.entries(AdTypesText).map(([key, value]) => ({ value: key, label: value }));
    const adApplicationTypeSelectorOptions = Object.entries(AdStatusText).map(([key, value]) => ({ value: key, label: value }));

    return (
      <AppLayout title={selectMode ?
        (<BackButton
          to={`/projects/${currentProject.slug}${realArea ? ('/' + realArea.value) : ''}`}
          path={[currentProject.name, `Lägg till leverantör till ${realArea && realArea.label}`]}
        />) : 'Leverantörer'
      }>
        <div className="column-content sp-main-container supplier-table-wrapper">
          <TableTopActions
            selectedTab={selectedTab}
            onTabChange={(index) => {
              this.setState({ selectedTab: index }, () => {
                const selectedTabName = getSelectedTabName(selectMode, index, isFeaturedSupplier);

                this.handleChange(0, 'start', true, { // Reset to page 1 when changing tabs
                  ...filterSearch,
                  order: selectedTabName === TabNames.AD_APPLICATIONS ? undefined : [{ column: '0', dir: 'asc' }],
                  searchFilter: selectedTabName === TabNames.AD_APPLICATIONS ? '' : filterSearch.searchFilter
                })
              });
            }}
            onAddArea={!selectMode && this.showAddAreaModal}
            onAddSupplier={this.onAddSupplier}
            onRecategorizeSuppliers={() => this.setState(prev => ({ modalRecategorizeShow: true, modalRecategorizeKey: prev.modalRecategorizeKey + 1 }))}
            isReadOnlyUser={appState.isReadOnlyAccessRole()}
            numberOfRecommendedSuppliers={numberOfRecommendedSuppliers}
            selectMode={selectMode}
            isFeaturedSupplier={isFeaturedSupplier}
            inboxCount={appState.getSupplierApplicationCount()}
            onShowSupplierApplicationInfo={() => this.setState({ showSupplierApplicationInfo: true })}
            selectedTabName={selectedTabName}
          />

          {selectedTabName !== TabNames.RECOMMENDED && (
            <SearchBarWithActions className='supplier-database-searchBar-container' value={filterSearch.searchFilter} onChange={(value) => this.search(value)} style={{ marginBottom: '20px' }}>
              {selectedTabName !== TabNames.AD_APPLICATIONS ? (
                <>
                  <Selector
                    isClearable
                    placeholder='Yrkeskategori'
                    options={areas}
                    value={filterSearch.areaId ? areas.find(area => area.value == filterSearch.areaId) : null}
                    onChange={(e) => this.handleChange(e ? e.value : null, 'areaId', true)}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  <Selector
                    isClearable
                    placeholder='Län'
                    options={regions}
                    value={filterSearch.county ? { label: filterSearch.county, value: filterSearch.county } : null}
                    onChange={(e) => this.handleChange(e ? e.value : null, 'county', true)}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  <Selector
                    isClearable
                    placeholder='Kommun'
                    options={cities}
                    value={filterSearch.municipality ? { label: filterSearch.municipality, value: filterSearch.municipality } : null}
                    onChange={(e) => this.handleChange(e ? e.value : null, 'municipality', true)}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  <Selector
                    isClearable
                    isSearchable={false}
                    placeholder='Betyg minst'
                    options={ratingsDropdown}
                    value={filterSearch.rating ? this.state.ratingsDropdown.find(rd => rd.value == filterSearch.rating) : null}
                    onChange={(e) => this.handleChange(e ? e.value : null, 'rating', true)}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  <Selector
                    isClearable
                    isSearchable={false}
                    placeholder='Spärrade'
                    options={[{ value: "true", label: "Endast spärrade" }, { value: "false", label: "Göm spärrade" }]}
                    value={filterSearch.blocked ? [{ value: "true", label: "Endast spärrade" }, { value: "false", label: "Göm spärrade" }].find(opt => opt.value == filterSearch.blocked) : null}
                    onChange={(e) => this.handleChange(e ? e.value : null, 'blocked', true)}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                  {appState.isSuperAdminOrg() && (
                    <Selector
                      isClearable
                      isSearchable={false}
                      placeholder='Utvald'
                      options={featuredOptions}
                      value={filterSearch.featured ? featuredOptions.find(x => x.value == filterSearch.featured) : null}
                      onChange={(e) => this.handleChange(e ? e.value : null, 'featured', true)}
                    />
                  )}
                </>
              ) : (
                <>
                    <Selector
                      isClearable
                      isSearchable={false}
                      placeholder='Typ'
                      options={adApplicationTypeSelectorOptions}
                      value={filterSearch.adType ? adApplicationTypeSelectorOptions.find(opt => opt.value == filterSearch.adType) : null}
                      onChange={(e) => this.handleChange(e ? e.value : null, 'adType', true)}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                    <Selector
                      isClearable
                      isSearchable={false}
                      placeholder='Status'
                      options={adApplicationStatusSelectorOptions}
                      value={filterSearch.adStatus ? adApplicationStatusSelectorOptions.find(opt => opt.value == filterSearch.adStatus) : null}
                      onChange={(e) => this.handleChange(e ? e.value : null, 'adStatus', true)}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                </>
              )}
            </SearchBarWithActions>
          )}

          <div className="supplier-db-main-table-wrap">
            <MaterialTable
              key={selectedTab}
              tableRef={this.tableRef}
              icons={tableIcons}
              isLoading={isLoading}
              options={{
                showSelectAllCheckbox: false,
                detailPanelType: 'single',
                sorting: false,
                draggable: false,
                search: false,
                pageSize: 10,
                pageSizeOptions: [10],
                toolbar: false,
                detailPanelColumnStyle: { display: 'none' },
                rowStyle: rowData => ({
                  color: rowData.isBlacklist
                    ? 'var(--gray-400)'
                    : '',
                }),
                selection: selectMode,
                selectionProps: (rowData) => {
                  const isChecked = selectedSupplierIds && selectedSupplierIds.findIndex(s => s === rowData.sup_id) > -1;
                  return ({
                    icon: <StyledCheckbox onChange={() => this.onSelectionChange(null, rowData)}
                      checked={isChecked}
                    />,
                    checkedIcon: <StyledCheckbox onChange={() => this.onSelectionChange(null, rowData)}
                      checked={isChecked}
                    />,
                    checked: isChecked,
                    disabled: rowData.isBlacklist,
                    disableRipple: true,
                    color: "default"
                  })
                }
              }}
              localization={{ body: { emptyDataSourceMessage: 'Kunde inte hitta något resultat' } }}
              columns={this.getColumns(isFeaturedSupplier)}
              data={data.data || []}
              onRowClick={selectedTabName !== TabNames.AD_APPLICATIONS ? this.handleRowClick : (_, rowData) => {
                this.setState({ selectedAdIndex: rowData.tableData.id, showAdModal: true });
              }}
              onSelectionChange={selectMode ? this.onSelectionChange : undefined}
              detailPanel={rowData => {
                const { selectMode } = this.props;

                if (selectedTabName === TabNames.AD_APPLICATIONS) return;

                return (
                  <SupplierDetailPanel
                    rowData={rowData}
                    supId={rowData.sup_id}
                    key={rowData.sup_id}
                    tableRef={this.tableRef}
                    hasSyna={rowData.hasSyna}
                    selectMode={selectMode}
                    selectedContactPersons={contactPersons}
                    onSelectContactPerson={this.onSelectContactPerson}
                    renderActions={this.renderActions}
                    checkbox={
                      selectMode && (
                        <StyledCheckbox
                          style={{ marginLeft: '-25px', marginRight: '40px' }}
                          onChange={() => this.onSelectionChange(null, rowData)}
                          checked={rowData.isChecked}
                        />
                      )
                    }
                    onFeaturedSupplierSubmit={(supplierName) => this.setState({ selectedTab: appState.isAdminRole() ? 0 : 1 }, () => {
                      this.search(supplierName);
                      this.fetchSuppliers(this.state.filterSearch)
                    })}
                    history={this.props.history}
                  />
                );
              }}
              components={{
                Pagination: () => null,
              }}
            />
          </div>

          <div className="sup-pagination">
            {data.totalCount > 0 && (
              <Pagination
                count={Math.ceil(data.totalCount / 10)}
                onChange={(_, value) => this.onPageChanged(value)}
                page={this.state.currentPage}
              />
            )}
          </div>

          {selectMode && (
            <div className="sup-pagination selected-supplier-mod-container">
              <SelectedSupplierModule
                contactPersons={contactPersons}
                quoteVersion={quoteVersion}
                quoteVersions={quoteVersions}
                selectedSuppliers={selectedSuppliers}
                onRemoveSupplier={this.onSelectionChange}
                onSelectContactPerson={this.onSelectContactPerson}
                onToggleQuotation={this.onToggleQuotation}
                handleQuoteExpiry={this.handleQuoteExpiry}
                extendedDate={extendedDate}
                checkQuotationExpiry={this.checkQuotationExpiry}
                onSaveSelectedSuppliersClick={() => {
                  const checkExpiry = this.checkQuotationExpiry(moment(extendedDate).endOf('day').utc())
                  if (
                    quoteVersion &&
                    extendedDate &&
                    checkExpiry &&
                    contactPersons?.length
                  ) {
                    this.setState({ errorMsg: 'Välj utgångsdatum' });
                    return;
                  }

                  this.props.onSaveSelectedSuppliersClick();
                }}
                showLoading={this.props.showLoading}
                errorMsg={errorMsg}
              />
            </div>
          )}

          <AppAddEditSupplier
            key={"app-add-edit-supplier" + addEditSupplierModalKey}
            show={addUpdateSupplier}
            setShow={(value) => this.setState({ addUpdateSupplier: value })}
            saveSupplier={this.saveSupplier}
            editSupplierData={editSupplierData}
            intl={this.props.intl}
            onModalHide={this.toggleAddSupplierModal}
            fetchNewAreas={this.fetchAreas}
            areas={this.props.areas}
            supplierApplication={false}
            history={this.props.history}
          />

          <BtModal
            center
            name="modal-add-area"
            show={modalAddAreaShown}
            key={"modal-add-area" + modalAddAreaKey}
          >
            <AppAddArea
              handleAddAreaWithSuppliers={this.handleAddAreaWithSuppliers}
              loading={modalAddAreaDataLoading}
              closeModal={() => this.setState({ modalAddAreaShown: false })}
            />
          </BtModal>

          <SupplierAdPopUp
            show={showAdNotificationModal}
            setShow={(value) => this.setState({ showAdNotificationModal: value })}
            areaName={this.props.areaName}
            municipalityName={this.props?.currentProject?.province}
            setTabToRecommended={() => this.setState({ selectedTab: 1 }, () => this.fetchSuppliers(this.state.filterSearch))}
            numberOfRecommendedSuppliers={numberOfRecommendedSuppliers}
          />
          <Modal
            title="Ansökningsformulär för nya leverantörer"
            show={this.state.showSupplierApplicationInfo}
            setShow={(v) => this.setState({ showSupplierApplicationInfo: v })}
            hasCloseButton
          >
            <div style={{ maxWidth: "770px", fontSize: "14px" }}>
              <p>Nya leverantörer kan ansöka om att bli tillagda via formuläret på <a href={supplierApplicationForm} target="_blank" rel="noreferrer">{supplierApplicationForm}</a>.
                Det är öppet för alla och kräver ingen inloggning.</p>

              <p>Nya ansökningar hamnar under fliken "ansökningar" i leverantörsdatabasen. Administratörer kan granska ansökningar för att därefter godkänna eller neka (i menyn för respektive leverantörsansökan).
                Godkända ansökningar hamnar direkt i leverantörsdatabasen. Nekade ansökningar hamnar under fliken "nekade ansökningar". Leverantörer kan inte ansöka igen om inte deras tidigare ansökan tas bort först.</p>
            </div>
          </Modal>

          {data?.data?.[selectedAdIndex] && (
            <AdModal
              title="Ansökan"
              show={showAdModal}
              setShow={(value) => this.setState({ showAdModal: value })}
              adApplication={data.data[selectedAdIndex]}
              setAdApplication={(getNewState) => {
                const newData = [...data.data];
                newData[selectedAdIndex] = getNewState(data.data[selectedAdIndex]);
                this.setState({ data: { ...data, data: newData } });
              }}
              submitFeaturedSupplierAd={() => this.submitFeaturedSupplierAd(data.data[selectedAdIndex])}
              submitRecommendedSupplierAd={() => this.submitRecommendedSupplierAd(data.data[selectedAdIndex])}
              submitting={submittingAd}
              setSubmitting={(value) => this.setState({ submittingAd: value })}
              areas={areas.map(x => {
                return { ...x, id: x.value, value: x.label };
              })}
              isReviewMode={true}
            />
          )}
          <BlockSupplierModal
            key={this.state.blockModal.key}
            show={this.state.blockModal.show}
            onClose={() => this.setState({ blockModal: { ...this.state.blockModal, show: false } })}
            onSuccess={(isBlacklist, reasons) => {
              this.setState(prev => {
                const data = [...prev.data.data];
                const supplier = data.find(d => d.sup_id == this.state.blockModal.rowData.sup_id);
                if (supplier) {
                  supplier.isBlacklist = isBlacklist;
                  supplier.reasons = reasons;
                }
                return { ...prev, data: { ...prev.data, data } };
              });
            }}
            supplierId={this.state.blockModal.rowData.sup_id}
            supplierName={this.state.blockModal.rowData.sup_name}
            reasons={this.state.blockModal.rowData.reasons ?? []}
            block={this.state.blockModal.block}
          />
          {data?.data?.[selectedAdIndex] && (
            <Modal
              show={this.state.rejectionReasonModalVisibility}
              setShow={(value) => this.setState({ rejectionReasonModalVisibility: value })}
              title='Nekade ansokan'
              buttonInfo={{
                label: 'Skicka', action: () => {
                  this.setState({ rejectionReasonModalVisibility: false });
                  this.updateAdStatus(data?.data?.[selectedAdIndex]._id, AdApplicationStatus.REJECTED, this.state.rejectionReason);
                }
              }}
            >
              <Field label="Skriv anledning" value={this.state.rejectionReason} onChange={(value) => this.setState({ rejectionReason: value })} />
            </Modal>
          )}
        </div>
        <RecategorizeModal
          show={this.state.modalRecategorizeShow}
          setShow={(value) => this.setState({ modalRecategorizeShow: value })}
          key={this.state.modalRecategorizeKey}
          areas={this.state.areas}
          onSave={(modifiedAreaId) => {
            this.fetchSuppliers(this.state.filterSearch);
          }}
        />
      </AppLayout>
    );
  }
}
