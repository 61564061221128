import React, { memo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Logo from '../../components/svgs/logo';
import { areEqual } from '../../util';
import _ from 'lodash';
import UserDetailsForm from './user-details';
import CraneImage from '../../assets/images/crane.jpg';
import BtBackground from '../bt-background/bt-background';
import BtGrid from '../bt-grid/bt-grid';
import BtRow from '../bt-row/bt-row';
import BtColumn from '../bt-column/bt-column';
import BtCenter from '../bt-center/bt-center';

const Signup = memo(({ isProfile, location, history }) => {
  const [userData, setUserData] = React.useState(null);

  useEffect(() => {
    if (location && location.state && location.state.userData) {
      location.state.userData.password = '';
      setUserData(location.state.userData);
      history.replace(location.pathname, null);
    } else {
      history.push('/error');
    }
  }, []);

  return (
    <div className="page-login">
      <BtGrid>
        <BtRow>
          <BtColumn className="bt-w50 first-column bg-white" style={{ padding: '70px 0' }}>
            <BtCenter>
              <div className="align-center bt-w60">
                <div className="m-t-30 m-b-40 d-flex justify-content-center">
                  <Logo size="350px" type="logoWithTitle" />
                </div>
                <h3>Välkommen</h3>
                <p>
                  {`${userData && userData.organizationName} använder Accurator, byggbranschens smarta inköpsverktyg, för att hålla
                  reda på leverantörer, upphandlingar, kommunikation, avtal och erfarenheter. Fyll i
                  uppgifterna nedan för att komma igång.`}
                </p>
                <UserDetailsForm isProfile={isProfile} user={userData} location={location} history={history} />
              </div>
            </BtCenter>
          </BtColumn>
          <BtColumn className="second-column">
            <BtBackground
              className="bg-login bt-background-position-left"
              src={CraneImage}
            />
          </BtColumn>
        </BtRow>
      </BtGrid>
    </div>
  );
}, areEqual);

export default injectIntl(Signup);
