import Card from "../../components/shared/Card/card"
import StyledCheckbox from "../../components/shared/StyledCheckbox/StyledCheckbox";
import { serviceOrganization } from "../../services/service-organization";
import appState from "../../state/AppStateContainer";
import styles from "./OrganizationSettings.module.scss";
import { autosave } from "../../util";
import RecommendedSubscription from "./RecommendedSubscription";
import PublishedQuotationsSubscription from "./PublishedQuotationsSubscription";
import Field from "../../components/shared/Field/Field";
import { useState } from "react";

const OrganizationSettings = () => {
  const invoiceEmail = appState.getInvoiceEmail();
  const invoiceRef = appState.getInvoiceRef();
  const [invoiceEmailErrorMessage, setInvoiceEmailErrorMessage] = useState('');
  const [invoiceRefErrorMessage, setInvoiceRefErrorMessage] = useState('');
  const [hasInvoiceEmailBeenEdited, setHasInvoiceEmailBeenEdited] = useState(false);
  const [hasInvoiceEmailBeenRef, setHasInvoiceRefBeenEdited] = useState(false);
  const onlyAdminsCanCreateSuppliers : boolean = appState.getCurrentOrg()?.onlyAdminsCanCreateSuppliers ?? false;

  const updateInvoiceEmail = async (email: string) => {
    if (!hasInvoiceEmailBeenEdited) return;
    autosave(async () => {
      const response = await serviceOrganization.updateField("invoiceEmail", email);
      if (response.invoiceEmail) {
        setInvoiceEmailErrorMessage(response.invoiceEmail);
        setHasInvoiceEmailBeenEdited(false);
      } else if (response.error) {
        throw new Error(response.error);
      } else {
        setInvoiceEmailErrorMessage('');
        setHasInvoiceEmailBeenEdited(false);
      }
      await appState.fetchOrganizations();
    });
  };
  const updateInvoiceRef = async (ref: string) => {
    if (!hasInvoiceEmailBeenRef) return;

    autosave(async () => {
      const response = await serviceOrganization.updateField("invoiceRef", ref);
      if (response.invoiceRef) {
        setInvoiceRefErrorMessage(response.invoiceRef);
        setHasInvoiceRefBeenEdited(false);
      } else if (response.error) {
        throw new Error(response.error);
      } else {
        setInvoiceRefErrorMessage('');
        setHasInvoiceRefBeenEdited(false);
      }

      await appState.fetchOrganizations();
    });
  };
  
  const toggleOnlyAdminsCanAddSuppliers = async () => {
    autosave(async () => {
      await serviceOrganization.saveSettings({
        onlyAdminsCanCreateSuppliers: !onlyAdminsCanCreateSuppliers,
      });
      await appState.fetchOrganizations();  
    });
  }

  const renderPurchasingSettings = () => {
    return (
      <label className={styles.checkboxLabel}><StyledCheckbox checked={onlyAdminsCanCreateSuppliers} onChange={toggleOnlyAdminsCanAddSuppliers}/> Endast administratörer kan lägga till leverantörer</label>
    );
  }

  const renderSalesSettings = () => {
    return (
      <div style={{fontSize: "14px", color: "var(--gray-700)"}}>
        <h3 style={{ fontSize: "18px", color: "var(--gray-900)", fontWeight: 500 }}>Prenumerationstjänster</h3>
        <RecommendedSubscription />
        <PublishedQuotationsSubscription />
        <h3 style={{ fontSize: "18px", color: "var(--gray-900)", fontWeight: 500 }}>Fakturauppgifter</h3>
        <div style={{ width: '400px', display: 'flex', flexDirection: 'column', gap: '8px', }}>
          <Field
            label='E-postadress'
            value={invoiceEmail}
            onChange={(value) => {
              appState.setInvoiceEmail(value);
              setHasInvoiceEmailBeenEdited(true);
            }}
            onBlur={(e) => updateInvoiceEmail(e.target.value)}
            errorMessage={invoiceEmailErrorMessage}
          />
          <Field
            label='Referens'
            value={invoiceRef}
            onChange={(value) => {
              appState.setInvoiceRef(value);
              setHasInvoiceRefBeenEdited(true);
            }}
            onBlur={(e) => updateInvoiceRef(e.target.value)}
            errorMessage={invoiceRefErrorMessage}
          />
        </div>
      </div>
    );
  }

  return (
    <Card>
      {appState.isPurchasingActive() && renderPurchasingSettings()}
      {appState.isSalesActive() && renderSalesSettings()}
    </Card>
  );
}

export default OrganizationSettings;