import { FC, useEffect, useState } from "react";
import AppAddEditSupplier from "../app-add-supplier/app-add-supplier";
import { serviceAreas } from "../../services/service-areas";
import appState from "../../state/AppStateContainer";
import styles from "./page-supplier-signup.module.scss";
import Card from "../../components/shared/Card/card";
import BtButton from "../bt-button/bt-button";
import { RouteComponentProps } from "react-router-dom";

type Props = {} & RouteComponentProps
type Area = { name: string, _id: string }
type BigNumbers = {
  contactedSuppliers: number,
  activeBidCards: number,
  finishedDeals: number,
  activeUsers: number,
  amountInBillionSEK: string,
}

const SupplierSignup : FC<Props> = ({ history }) => {
  const [areas, setAreas] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [bigNumbers, setBigNumbers] = useState<BigNumbers>();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch("https://accurator-report-test.azurewebsites.net/api/BigNumbersForWebsiteGet?code=6ToS24vUb5I83KQsYTit-P30voc5che5u3Ti0aturchiAzFuze0KOw%3D%3D");
        setBigNumbers(await response.json());
      } catch (e) {

      }
    })();
  }, [])


  const renderFooter = () => {
    return (
      <footer className='bidFormFooter'>
        <img src="https://cdn.prod.website-files.com/60ca0e6159e2663916316a16/67d0ef99ab5d5a6fcc0e4f66_Logos_Accurator.png" alt="Accurator" width="175" height="60" />
        
        <p>
          Vid frågor om Accurator, kontakta Accurator support:<br/>
          08-156 225, <a href='mailto:support@accurator.se'>support@accurator.se</a>
        </p>

        <p>
          <em>Hur lämnar jag anbud?</em><br/>
          <a target='_blank' rel='noreferrer' href="https://www.accurator.se/sidor/information-for-leverantorer">Instruktionsvideo för leverantörer</a>
        </p>
      </footer>
    )
  }

  const fetchAreas = async () => {
    const areasData = await serviceAreas.getAreas();
    const areas = areasData.forEach((item : Area) => ({ label: item.name, value: item._id }));
    setAreas(areas);
  };

  const orgName = appState.getCurrentOrgName();

  return <>          
    <img src={appState.getCurrentOrgSlug() === 'superadmin' ? 'https://cdn.prod.website-files.com/60ca0e6159e2663916316a16/67d0ef99ab5d5a6fcc0e4f66_Logos_Accurator.png' : `/api/organizations/${appState.getCurrentOrgSlug()}/logo`} style={{display: "block", margin: "2em auto", maxWidth: "350px"}} />
    {submitted ? (<>
      <Card className={styles.signup} title="Ansökan skickad">
        <p><b>Tack för din ansökan!</b></p>
        <p>Du har nu skickat in din ansökan till <b>{orgName}</b>. Vi granskar din ansökan och återkommer så snart som möjligt.</p>
      </Card>
      <Card className={styles.signup} title="Leverantörskonto">
        <p>Vill du öka dina chanser att få fler uppdrag?</p>
        <p><b>Skapa ett kostnadsfritt konto!</b></p>
        <p>Med ett konto hos accurator kan du ta kontroll över hur du presenteras i våra inköpares olika leverantörsregister och se alla dina förfrågningar på ett ställe.</p>
        <p>Våra tilläggstjänster ger också möjlighet att marknadsföra ert bolag till inköpare som just ska skicka ut en förfrågan i er kategori och att lämna anbud på uppdrag som är publicerade på vår marknadsplats.</p>
        {bigNumbers && (
          <div className={styles.bigNumbers}>
            <p>Accurator i siffror:</p>
            <div className={styles.row}>
              <div>
                <div className={styles.number}>{bigNumbers.contactedSuppliers}</div>
                <div className={styles.label}>Kontaktade leverantörer</div>
              </div>
              <div>
                <div className={styles.number}>{bigNumbers.activeBidCards}</div>
                <div className={styles.label}>Pågående upphandlingar</div>
              </div>
              <div>
                <div className={styles.number}>{bigNumbers.finishedDeals}</div>
                <div className={styles.label}>Avslutade affärer</div>
              </div>
              <div>
                <div className={styles.number}>{bigNumbers.activeUsers}</div>
                <div className={styles.label}>Inköpare</div>
              </div>
              <div>
                <div className={styles.number}>{bigNumbers.amountInBillionSEK.replace(".", ",")} mdkr</div>
                <div className={styles.label}>Upphandlat</div>
              </div>
            </div>
          </div>
        )}
        <iframe src="https://accurator.se/kunder" width="100%" height="210" frameBorder="0" scrolling="no"></iframe>
        <div className={styles.signupButton}><BtButton onClick={() => history.push('/supplier-sign-up')}>Skapa leverantörskonto</BtButton></div>
      </Card>
    </>) : (
      <Card className={styles.signup} title={appState.getCurrentOrgSlug() === 'superadmin' ? "Beställningsformulär: Rekommenderad leverantör" : "Leverantörsansökan"}>
        {appState.getCurrentOrgSlug() === 'superadmin' ? (<>
          <p>Använd detta formulär för att beställa Accurators tilläggstjänster för leverantörer. Uppgifterna sparas i en leverantörsdatabas i inköpssystemet Accurator. <a href="https://www.accurator.se/sidor/gdpr">Läs om personuppgiftsbehandlingen.</a></p>
          <p>Accurators rekommenderade leverantörer måste uppfylla vissa krav. Vi granskar därför alla beställningar innan de godkänns. Fyll i beställningen så hör vi av oss så snart som möjligt.</p> 
        </>) : (<>
          <p>Ansök om att bli leverantör till {orgName}. Uppgifterna sparas i en leverantörsdatabas i inköpssystemet Accurator. <a href="https://www.accurator.se/sidor/gdpr">Läs om personuppgiftsbehandlingen.</a></p>
          <p>Det är {orgName} som granskar och bedömer ansökan. Om ansökan godkänns kan kontaktpersonerna nedan börja få förfrågningar från {orgName} via e-post från noreply@accurator.se.</p> 
        </>)}
        
        <AppAddEditSupplier 
          show={true}
          setShow={() => {}}
          saveSupplier={() => {
            setSubmitted(true);
          }}
          editSupplierData={ {
            name: '',
            supplierId: '',
            areaIds: [],
            organizationNumber: '',
            description: '',
          }}  
          onModalHide={() => {}}
          fetchNewAreas={fetchAreas}
          areas={areas}
          supplierApplication={true}
        />
      </Card>  
    )}
    {renderFooter()}
  </>;
}

export default SupplierSignup;